.calmchristian-channels {
    background: $dark;
    padding: baseline(30) baseline(20);
    overflow: hidden;

    &-genre {
        margin-left: auto;
        margin-right: auto;
        max-width: $page;

        & + .calmchristian-channels-genre {
            margin-top: baseline(15);
        }

        &[data-has-only-one-category="true"] {
            .calmchristian-channels-category-title {
                display: none;
            }

            .calmchristian-channels-category-content {
                height: auto!important;
                opacity: 1!important;
                overflow: visible!important;
            }
        }
    }

    &-category {
        &-title {
            border-bottom: 1px solid transparent;
            color: $white;
            cursor: pointer;
            font-size: ms(8);
            margin: 0 12px;
            overflow: hidden;
            padding: baseline(5) 0;
            position: relative;

            & > span {
                display: block;
                transform: translateX(0);
                transition: all .25s ease-out;
                will-change: transform;
            }

            &:after {
                border: 1px solid $gray-light-alt;
                border-right-color: transparent;
                border-bottom-color: transparent;
                content: "";
                height: 12px;
                position: absolute;
                right: 40px;
                top: 50%;
                transform: translateY(calc(-50% + 8px)) rotate(45deg);
                transition: all .25s ease-out;
                width: 12px;
            }
        }

        &-thumbnail {
            border-radius: 5px;
            height: 64px;
            left: -80px;
            opacity: 0;
            position: absolute;
            top: 13px;
            transform: scale(.5);
            transform-origin: left center;
            transition: all .25s ease-out;
            width: 64px;
            will-change: transform, opacity;
        }

        &.is-closed {
            .calmchristian-channels-category-title {
                &:after {
                    border-top-color: $gray-middle;
                    border-left-color: $gray-middle;
                    transform: translateY(-50%) rotate(-135deg);
                }

                & > span {
                    transform: translateX(76px);
                }

                .calmchristian-channels-category-thumbnail {
                    left: 0;
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }

        &-content {
            height: auto;
            opacity: 1;
            overflow: visible;
            transition: height .5s ease-out, opacity .35s ease-out;
        }

        &.is-closed {
            .calmchristian-channels-category-title {
                border-bottom: 1px solid lighten($dark-light, 5%);
                color: $brown-light;
            }

            .calmchristian-channels-category-content {
                height: 0;
                overflow: hidden;
                opacity: 0;
            }   
        }
    }

    header {
        margin: 0 auto;
        max-width: $page;
        width: 100%;

        h2 {
            color: $white;
            font-size: ms(15);
            font-weight: 400;
            margin: 0 0 baseline(15) 0;
            padding: 0 12px;
            position: relative;

            &:before {
                content: attr(data-letter);
                font-size: ms(30);
                left: baseline(-7);
                opacity: .05;
                position: absolute;
                top: baseline(-10);
                z-index: 0;
            }
        }
    }

    h2 {
        margin: 0;
    }

    .calmchristian-channels-more-text {
        color: $white;
        font-family: $font-decorative;
        font-size: ms(4);
        font-weight: 300;
        text-align: center;
        padding: baseline(15) 0;
    }

    .calmchristian-channels-items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: $page;
        padding: 0;
        transition: opacity .5s ease-out;
        width: 100%;
        will-change: opacity;

        &.is-loading {
            opacity: .25;
        }

        .calmchristian-channels-item {
            background: transparent;
            box-shadow: 0 5px 12px rgba(0, 0, 0, .2);
            border-radius: 3px;
            cursor: pointer;
            margin: 0 12px 24px 12px;
            opacity: 0;
            overflow: hidden;
            position: relative;
            transition: all .5s ease-out;
            width: calc((100% / 6) - 24px);
            will-change: transform;
            z-index: 1;

            &.is-favorited {
                .calmchristian-channels-item-fav {
                    background: $white;

                    & > svg {
                        opacity: 1;
                    }
                }
            }

            &.is-liked {
                .calmchristian-channels-item-like {
                    background: $white;
                    cursor: auto;

                    & > svg {
                        opacity: 1;
                    }
                }
            }

            &.is-disliked {
                .calmchristian-channels-item-dislike {
                    background: $white;
                    cursor: auto;

                    & > svg {
                        opacity: 1;
                    }
                }
            }

            .cr-player-play-icon-pause {
                display: none;
            }

            &.is-played {
                .cr-player-play-icon-play {
                    display: none;
                }

                .calmchristian-channels-item-play {
                    padding-left: 0;
                }
                
                .cr-player-play-icon-pause {
                    display: block;
                }
            }

            &:before,
            &:after {
                content: "";
                opacity: 0;
                transition: opacity .5s ease-out;
            }

            &-bg {
                background-color: transparent;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 2px;
                height: 100%;
                left: 0;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                top: 0;
                transform: scale(1.01);
                transition: opacity .5s ease-out;
                width: 100%;

                &.is-loaded {
                    opacity: 1;
                }
            }

            &-helper-image {
                display: block;
                height: auto;
                width: 100%;
            }

            &-play {
                align-items: center;
                background: rgba(0, 0, 0, .33);
                border: 1px solid $white;
                border-radius: $border-radius-full;
                box-shadow: 0 2px 2px rgba(0, 0, 0, .25);
                cursor: pointer;
                display: flex;
                fill: $white;
                height: 32px;
                justify-content: center;
                opacity: 0;
                padding-left: 5px;
                position: absolute;
                right: 50%;
                top: 50%;
                transform: translateX(50%) translateY(-50%);
                transition: all .5s ease-out;
                width: 32px;
                z-index: 2;
            }

            &-fav,
            &-like,
            &-dislike {
                align-items: center;
                background: rgba(0, 0, 0, .33);
                border-radius: $border-radius-full;
                bottom: 20px;
                cursor: pointer;
                display: flex;
                fill: $white;
                height: 20px;
                justify-content: center;
                opacity: 0;
                position: absolute;
                transition: all .5s ease-out;
                right: 12px;
                width: 20px;
                will-change: opacity;
                z-index: 2;

                svg {
                    fill: $white;
                    height: 12px;
                    opacity: .9;
                    width: 12px;
                }
            }

            &-like {
                bottom: 68px;
            }

            &-dislike {
                bottom: 44px;
            }

            &-expand {
                bottom: 10px;
                height: 40px;
                left: 0;
                opacity: 0;
                position: absolute;
                transition: all .5s ease-out;
                width: 100%;
                will-change: opacity;

                &:after,
                &:before {
                    background: $white;
                    content: "";
                    height: 1px;
                    left: 50%;
                    position: absolute;
                    transition: background .25s ease-out, top .25s ease-out;
                    top: 70%;
                    width: 12px;
                }

                &:after {
                    transform-origin: 100% 100%;
                    transform: translateX(-12px) rotate(25deg);
                }

                &:before {
                    transform-origin: 0 0;
                    transform: rotate(-25deg);
                }
            }

            &.is-visible {
                opacity: 1;
            }

            &.is-loading {
                animation: loadingItem;
                animation-iteration-count: infinite;
                animation-duration: 2s;
                animation-timing-function: linear;
                background: lighten($dark, 5%);
                box-shadow: 0 5px 12px rgba(0, 0, 0, 0);

                &:nth-child(1) {
                    animation-delay: .2s;
                }

                &:nth-child(2) {
                    animation-delay: .4s;
                }

                &:nth-child(3) {
                    animation-delay: .6s;
                }

                &:nth-child(4) {
                    animation-delay: .8s;
                }

                &:nth-child(5) {
                    animation-delay: 1s;
                }

                &:nth-child(6) {
                    animation-delay: 1.2s;
                }
            }

            &.is-active {
                .calmchristian-channels-item-play,
                .calmchristian-channels-item-expand {
                    opacity: 1;
                }
            }

            &:hover {
                .calmchristian-channels-item-bg {
                    opacity: .2;
                }

                .calmchristian-channels-item-expand {
                    bottom: 0;
                    opacity: 1;
                }

                .calmchristian-channels-item-play {
                    opacity: 1;
                    transform: translateX(50%) translateY(-50%) scale(1.5);
                }
            }
        }
    }

    &-expanded {
        background-position: right center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        height: 840px;
        opacity: 1;
        position: relative;
        transition: height .5s ease-out 0s, opacity .5s ease-out .5s;
        width: 100%;

        &.is-opened {
            transition: height .5s ease-out 0s, opacity .33s ease-out 0s;
        }

        &.is-closed {
            height: 0;
            pointer-events: none;
        }

        .calmchristian-channels-expanded-content-fav,
        .calmchristian-channels-expanded-content-like,
        .calmchristian-channels-expanded-content-dislike {
            svg {
                &.is-border {
                    margin: 1px auto 0 auto;
                }
            }
        }

        &.is-liked {
            .calmchristian-channels-expanded-content-like {
                cursor: auto;
                pointer-events: none;

                & > svg {
                    opacity: 1;
                }
            }
        }

        &.is-disliked {
            .calmchristian-channels-expanded-content-dislike {
                cursor: auto;
                pointer-events: none;

                & > svg {
                    opacity: 1;
                }
            }
        }

        &.is-closed {
            height: 0;
            opacity: 0;

            .calmchristian-channels-expanded-content {
                opacity: 0;
            }
        }

        &-image {
            background-color: $darkest;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            box-shadow: 0 0 50px $darkest, 0 0 100px $darkest, 0 0 150px $darkest;
            height: auto;
            left: 50%;
            opacity: 1;
            padding-bottom: 41%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: opacity .5s ease-out, box-shadow 1.5s ease-out;
            width: 72.5%;
            z-index: 1;

            &.is-hidden {
                box-shadow: 0 0 0 $darkest, 0 0 0 $darkest, 0 0 0 $darkest;
                opacity: 0;
                pointer-events: none;
            }

            img {
                height: 100px;
                left: 0;
                opacity: 0;
                pointer-events: none;
                position: absolute;
                top: 0;
                width: 100px;
            }
        }

        &-content {
            left: 0;
            opacity: 1;
            padding-bottom: 50px;
            padding-left: 50px;
            position: static;
            transition: all .5s ease-out, opacity .35s ease-out;
            z-index: 5;
            width: 100%;

            &.is-hidden {
                opacity: 0;
                visibility: hidden;
            }

            h4 {
                color: $brown-light;
                font-size: ms(16);
                max-width: 40%;
                position: relative;

                &:before {
                    content: attr(data-first-letter);
                    font-size: ms(42);
                    line-height: 1;
                    opacity: .05;
                    position: absolute;
                    transform: translateY(-40%) translateX(-75px);
                }
            }

            p {
                color: $color-text-light;
                margin-bottom: 40px;
                max-height: 200px;
                max-width: 40%;
                overflow: auto;
            }

            &-play {
                cursor: pointer;
                margin-top: .875rem;
                position: relative;

                svg {
                    fill: $white;
                    left: 30px;
                    pointer-events: none;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:active,
                &:focus,
                &:hover {
                    color: $white;
                }

                &-atmosphere {
                    display: none;
                }
            }

            &-fav,
            &-like,
            &-dislike {
                background: $darkest;
                border: none;
                border-radius: 50%;
                cursor: pointer;
                display: inline-block;
                height: 36px;
                margin-left: 30px;
                padding: 5px 0 0 0;
                position: relative;
                text-align: center;
                transition: opacity .5s ease-out;
                top: 15px;
                width: 36px;
                will-change: opacity;

                &:active,
                &:focus,
                &:hover {
                    & > svg {
                        fill: $brown-light;
                    }
                }

                & > svg {
                    fill: $white;
                    opacity: .9;
                    pointer-events: none;
                    transform: scale(.85);
                }
            }

            &-like,
            &-dislike {
                margin-left: 5px;
            }
        }

        &.is-liked {
            .calmchristian-channels-expanded-content-like {
                background: $brown;

                & > svg {
                    fill: $white;
                }
            }
        }

        &.is-disliked {
            .calmchristian-channels-expanded-content-dislike {
                background: $brown;
                
                & > svg {
                    fill: $white;
                }
            }
        }

        &.is-favorited {
            .calmchristian-channels-expanded-content-fav {
                background: $brown;
                
                & > svg {
                    fill: $white;
                    opacity: .9;
                }
            }
        }

        &-wrapper {
            align-items: center;
            display: flex;
            height: 100%;
        }
    }

    .calmchristian-channels-rows {
        .calmchristian-channels-row {
            .calmchristian-channels-item.is-atmosphere {
                .calmchristian-channels-item-fav,
                .calmchristian-channels-item-like,
                .calmchristian-channels-item-dislike {
                    display: none!important;
                }

                .calmchristian-channels-item-play {
                    & > svg {
                        display: none;
                    }

                    &:before,
                    &:after {
                        background: $white;
                        content: "";
                        height: 2px;
                        left: 9px;
                        position: absolute;
                        top: 15px;
                        width: 14px;
                    }

                    &:after {
                        height: 14px;
                        left: 15px;
                        top: 9px;
                        width: 2px;
                    }

                    &-big {
                        & > svg {
                            display: none;
                        }

                        &:before,
                        &:after {
                            background: $white;
                            content: "";
                            height: 2px;
                            left: 13px;
                            position: absolute;
                            top: 23px;
                            width: 22px;
                        }

                        &:after {
                            height: 22px;
                            left: 23px;
                            top: 13px;
                            width: 2px;
                        }
                    }
                }
            }
        }
    }

    .channels-loading-error {
        display: none;
    }

    &.has-loading-error {
        position: relative;

        .channels-loading-error {
            align-content: center;
            background: rgba(0, 0, 0, .95);
            display: flex;
            height: 100%;
            justify-content: center;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;

            &-wrapper {
                align-content: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

            &-heading {
                color: $white;
                display: block;
                font-weight: normal;
                margin: 0 0 3rem 0;
                text-align: center;
                width: 100%;
            }
        }
    }
}

@keyframes loadingItem {
  0%, 100% {
      background-color: lighten($dark, 5%);
  }

  50% {
    background-color: lighten($dark, 10%);
  }
}

body.player-not-loaded {
    .calmchristian-channels .calmchristian-channels-rows .calmchristian-channels-row .calmchristian-channels-item-play,
    .calmchristian-channels .calmchristian-channels-rows .calmchristian-channels-row .calmchristian-channels-item-play-big,
    .calmchristian-channels .calmchristian-channels-rows .calmchristian-channels-row .calmchristian-channels-item-dislike,
    .calmchristian-channels .calmchristian-channels-rows .calmchristian-channels-row .calmchristian-channels-item-fav,
    .calmchristian-channels .calmchristian-channels-rows .calmchristian-channels-row .calmchristian-channels-item-like {
        display: none;
    }

    .calmchristian-channels-expanded-content-play {
        display: none;
    }
}

@media (max-width: 1440px) {
    .calmchristian-channels {
        .calmchristian-channels-expanded-content h4 {
            font-size: ms(12);
        }
    }

    .calmchristian-channels {
        .calmchristian-channels-items {   
            .calmchristian-channels-item {
                margin: 0 10px 20px 10px;
                width: calc((100% / 5) - 20px);
            }
        }
    }
}

@media (max-width: 1280px) {
    .calmchristian-channels {
        .calmchristian-channels-items {   
            .calmchristian-channels-item {
                width: calc((100% / 4) - 20px);
            }
        }
    }
}

@media (max-width: 1140px) {
    .calmchristian-channels {
        padding: baseline(20) baseline(10);
        
        .calmchristian-channels-items {
            width: 100%;
        }

        .calmchristian-channels-expanded-content h4 {
            font-size: ms(10);
        }
    }
}

@media (max-width: 1040px) {
    .calmchristian-channels {
        padding: baseline(10) baseline(5);
        
        .calmchristian-channels-items {   
            .calmchristian-channels-item {
                width: calc((100% / 3) - 20px);
            }
        }

        .calmchristian-channels-expanded {
            &.is-closed {
                height: 0;
            }

            &-content {
                padding: 0 10px;

                h4 {
                    font-size: ms(12);
                }

                p {
                    max-height: 180px;
                }
            }
        }

        .channel-chapters-current-item {
            margin-bottom: 20px;
        }

        .calmchristian-channels-expanded-image {
            display: none;
        }

        .calmchristian-channels-expanded-content h4,
        .calmchristian-channels-expanded-content p {
            max-width: 100%;
        }

        .calmchristian-channels-expanded-content h4 {
            font-size: ms(8);
        }
    }
}

@media (max-width: 720px) {
    .calmchristian-channels {
        .calmchristian-channels-items {   
            .calmchristian-channels-item {
                width: calc((100% / 2) - 20px);
            }
        }
    }
}

@media (max-width: 640px) {
    .calmchristian-channels {        
        .calmchristian-channels-expanded-content {
            padding-left: 0;
        }

        .calmchristian-channels-expanded-content-fav {
            margin-left: 40px;
        }

        &-category {
            &-title {
                font-size: ms(6);
                margin: 0;
                padding: baseline(3) 0;

                &:after {
                    right: 5px;
                }
            }

            &-thumbnail {
                height: 40px;
                top: 10px;
                width: 40px;
            }

            &.is-closed {
                .calmchristian-channels-category-title > span {
                    transform: translateX(50px);
                }
            }
        }
    }

    .calmchristian-blog-featured {
        padding: baseline(10) 0;
    }
}

@media (max-width: 600px) {
    .calmchristian-channels {
        .calmchristian-channels-expanded-content-fav {
            margin-left: calc(50% - 60px);
        }

        .calmchristian-channels-expanded-content-dislike, 
        .calmchristian-channels-expanded-content-like,
        .calmchristian-channels-expanded-content-fav {
            margin-top: 30px;
        }
    }
}

@media (max-width: 540px) {
    .calmchristian-channels {        
        .calmchristian-channels-items {        
            .calmchristian-channels-item {
                margin: 0 0 20px 0;
                width: 100%;
            }
        }
    }
}

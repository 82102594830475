body {
    .mod-login {
        position: static!important;

        .pane {
            display: none;
        }
    }
}

.mod-calmradio-login {
    background: $dark;
    left: 50%;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all .25s ease-out;
    visibility: visible;
    width: 600px;
    z-index: 1;

    label {
        color: $gray-light;
    }

    input[type="text"], 
    input[type="password"], 
    input[type="email"], 
    input[type="www"], 
    input[type="tel"], 
    input[type="search"] {
        border-bottom: 1px solid $gray;
        color: $white;

        &:active,
        &:focus,
        &:hover {
            border-bottom-color: $gold;
        }
    }

    .mod-calmradio-username-reminder-link,
    .mod-calmradio-password-reminder-link,
    .mod-calmradio-login-terms a {
        &:active,
        &:focus,
        &:hover {
            color: $gray;
        }
    }

    .btn {
        background: $brown;
        border: none;
        color: $white;

        &:active,
        &:focus,
        &:hover {
            background: $gray;
            border: none;
            color: $white;
        }

        &.btn-outline {
            background: $gray;
            border: none;
            color: $white;

            &:active,
            &:focus,
            &:hover {
                background: $brown;
                border: none;
                color: $white;
            }
        }
    }

    .mod-calmradio-login-header {
        padding: baseline(8) baseline(8) 0 baseline(8);
    }

    #login_form {
        overflow: hidden;
        padding: 0 baseline(8) baseline(26) baseline(8);
        position: relative;

        .grecaptcha-badge {
            bottom: 118px!important;
            position: absolute!important;
        }

        .nog-captcha-container {
            align-items: center;
            display: flex;
            margin-top: 4rem;

            img {
                height: 46px;
                margin: 0 1rem;
            }

            &.loading {
                img {
                    opacity: .125;
                }
            }

            input.is-invalid {
                background: rgba(red($color-danger), green($color-danger), blue($color-danger), .1);
                border-bottom-color: $color-danger!important;
            }

            input.is-loading {
                opacity: .5;
                pointer-events: none;
            }

            .help-block.form-error {
                display: none;
            }
        }
    }

    &.is-register {
        padding: baseline(8) 0 0 0;

        .mod-calmradio-login-header {
            padding: 0 baseline(8);
        }

        #register_form {
            overflow: hidden;
            padding: 0 baseline(8) baseline(26) baseline(8);
            position: relative;

            .grecaptcha-badge {
                bottom: baseline(24)!important;
                position: absolute!important;
            }

            .nog-captcha-container {
                align-items: center;
                display: flex;
                margin-top: baseline(8);

                img {
                    height: 46px;
                    margin: 0 1rem;
                }

                &.loading {
                    img {
                        opacity: .125;
                    }
                }

                input.is-invalid {
                    background: rgba(red($color-danger), green($color-danger), blue($color-danger), .1);
                    border-bottom-color: $color-danger!important;
                }

                input.is-loading {
                    opacity: .5;
                    pointer-events: none;
                }

                .help-block.form-error {
                    display: none;
                }
            }
        }
    }

    &.is-hidden {
        opacity: 0;
        pointer-events: none;
        transform: translateX(-50%) translateY(-40%);
        visibility: hidden;
        z-index: 0;
    }

    &-message {
        background: $color-danger;
        color: $white;
        font-size: ms(-2);
        font-weight: 500;
        margin-top: baseline(5);
        padding: baseline(2) baseline(4);

        &.is-hidden {
            display: none;
            visibility: hidden;
        }

        span[data-action] {
            cursor: pointer;
            text-decoration: underline;

            &:active,
            &:focus,
            &:hover {
                opacity: .66;
            }
        }
    }

    &-button {
        background: $brown;
        padding-left: baseline(15);
        padding-right: baseline(15);

        .mod-calmradio-login-button-state-normal {
            display: inline;
        }

        .mod-calmradio-login-button-state-loading {
            display: none;
        }

        &.is-loading {
            opacity: .5;
            pointer-events: none;

            .mod-calmradio-login-button-state-normal {
                display: none;
            }

            .mod-calmradio-login-button-state-loading {
                display: inline;
            }
        }
    }

    &-header {
        color: $white;
        font-size: ms(5);
        font-weight: 400;
        line-height: 1;
        margin: 0 0 baseline(2) 0;
        text-align: left;
    }

    &-input {
        padding: baseline(5) 0 0 0;

        input.is-invalid {
            background: rgba(red($color-danger), green($color-danger), blue($color-danger), .1);
            border-bottom-color: $color-danger!important;
        }

        input.is-loading {
            opacity: .5;
            pointer-events: none;
        }
    }

    &-radio-buttons {
        display: flex;

        .radio-button + .radio-button {
            margin-left: baseline(8);
        }
    }

    &-links {
        display: flex;
        font-size: ms(-2);
        justify-content: space-between;
        padding: baseline(8) 0;

        .checkbox {
            width: 40%;
        }

        a {
            width: 28%;
        }
    }

    &-terms {
        font-size: ms(-2);
        padding: baseline(8) 0 baseline(5);

        & > div {
            padding: baseline(1) 0;
        }
    }

    &-register,
    &-social-disclaimer {
        bottom: baseline(-15);
        color: $gray-medium;
        font-size: ms(-2);
        font-weight: 400!important;
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;

        strong {
            font-weight: 400;
        }

        a {
            color: $gold;

            &:active,
            &:focus,
            &:hover {
                color: $brown;
            }
        }
    }

    &-social-disclaimer {
        bottom: baseline(-9);
        color: $gray-medium;
        font-size: ms(-2);
    }

    &-social {
        bottom: 0;
        display: flex;
        left: 0;
        position: absolute;
        right: 0;

        &.is-loading {
            opacity: .5;
            pointer-events: none;
        }
    }

    &-with-fb,
    &-with-google {
        align-items: center;
        background: $color-fb;
        color: $white;
        display: flex;
        font-size: ms(-2);
        font-weight: 500;       
        justify-content: center;
        padding: baseline(3) baseline(3);
        width: 50%;

        &:active,
        &:focus,
        &:hover {
            background: lighten($color-fb, 8%);
            color: $white;
            text-decoration: none;
        }

        svg {
            fill: $white;
            margin-right: baseline(5);
        }
    }

    &-with-google {
        background: $brown;
        border: none;
        color: $white;

        &:active,
        &:focus,
        &:hover {
            background: $gray;
            color: $white;
            text-decoration: none;
        }
    }

    &-success {
        padding: baseline(5) baseline(8) baseline(15) baseline(8);

        &.is-hidden {
            display: none;
            visibility: hidden;
        }

        h2 {
            font-size: ms(5);
            font-weight: 400;
            line-height: 1;
            margin: 0 0 baseline(5) 0;
            text-align: center;
        }

        p {
            text-align: center;
        }
    }

    &.logout {
        padding-bottom: baseline(8);
        width: 500px;

        .btn {
            margin-top: baseline(8);
        }
    }

    &.username-reminder {
        padding: baseline(8) baseline(8) baseline(12) baseline(8);

        .mod-calmradio-login-header {
            padding: 0;
        }

        .mod-calmradio-login-username-reminder-email {
            margin-bottom: baseline(8);
        }

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
            transform: translateX(-50%) translateY(-40%);
            visibility: hidden;
            z-index: 0;
        }

        p,
        form {
            margin: baseline(6) 0;
        }

        input.is-invalid {
            background: rgba(red($color-danger), green($color-danger), blue($color-danger), .1);
            border-bottom-color: $color-danger!important;
        }

        input.is-loading {
            opacity: .5;
            pointer-events: none;
        }

        .mod-calmradio-login-username-reminder-message-success {
            background: $green;
        }

        .mod-calmradio-username-reminder-button {
            &-state-normal {
                display: inline;
            }

            &-state-loading {
                display: none;
            }

            &.is-loading {
                opacity: .5;
                pointer-events: none;

                .mod-calmradio-username-reminder-button-state-normal {
                    display: none;
                }

                .mod-calmradio-username-reminder-button-state-loading {
                    display: inline;
                }
            }
        }

        .mod-calmradio-username-reminder-cancel-button {
            float: right;
        }
    }

    &.password-reminder {
        padding: baseline(8) baseline(8) baseline(12) baseline(8);

        .mod-calmradio-login-header {
            padding: 0;
        }

        .mod-calmradio-login-password-reminder-email {
            margin-bottom: baseline(8);
        }

        input.is-invalid {
            background: rgba(red($color-danger), green($color-danger), blue($color-danger), .1);
            border-bottom-color: $color-danger!important;
        }

        input.is-loading {
            opacity: .5;
            pointer-events: none;
        }

        &-is-hidden {
            opacity: 0;
            pointer-events: none;
            transform: translateX(-50%) translateY(-40%);
            visibility: hidden;
            z-index: 0;
        }

        p,
        form {
            margin: baseline(5) 0;
        }

        .mod-calmradio-login-password-reminder-message-success {
            background: $green;
        }

        .mod-calmradio-password-reminder-button {
            &-state-normal {
                display: inline;
            }

            &-state-loading {
                display: none;
            }

            &.is-loading {
                opacity: .5;
                pointer-events: none;

                .mod-calmradio-password-reminder-button-state-normal {
                    display: none;
                }

                .mod-calmradio-password-reminder-button-state-loading {
                    display: inline;
                }
            }
        }

        .mod-calmradio-password-reminder-cancel-button {
            float: right;
        }
    }

    .password-reminder-form .grecaptcha-badge, 
    .username-reminder-form .grecaptcha-badge {
        bottom: 15px!important;
        left: 50%;
        position: absolute!important;
        visibility: visible!important;
        transform: translateX(-50%);
    }
}

body .mod-login.password-retrieval {
    background: $white;
    left: 50%;
    min-height: auto;
    max-width: 100%;
    padding: baseline(8);
    position: absolute!important;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all .25s ease-out;
    width: 600px;
    z-index: 2;

    .pane.reset-pane.active {
        overflow: visible;

        .loader {
            background: rgba(255,255,255,.75);

            .ripple {
                animation: ripple-loader 0.8s ease-in-out infinite;
                background: none;
                border-radius: 50%;
                border: 3px solid rgba(0, 0, 0, .125);
                border-top-color: $gray-medium;
                content: '';
                height: 64px;
                left: 50%;
                margin: 0;
                position: absolute;
                top: 50%;
                transform: translateX(-50%) translateY(-50%) rotate(0deg);
                width: 64px;
            }
        }
    }

    .pane.login-pane.active,
    .pane.reset-pane.active {
        display: block;
        position: static;

        .form-header {
            font-size: ms(4);
            font-weight: 400;
            line-height: 1;
            margin: 0 0 2rem;
            text-align: left;
        }

        .input-wrapper {
            padding: 0 0 baseline(5) 0;

            input.is-invalid {
                background: rgba(red($color-danger), green($color-danger), blue($color-danger), .1);
                border-bottom-color: $color-danger!important;
            }

            input.is-loading {
                opacity: .5;
                pointer-events: none;
            }
        }

        .help-block.form-error {
            color: $color-danger;
            padding: .5rem 0;
        }

        .btn {
            display: block;
            margin: baseline(4) auto 0 auto;
        }

        // Additonal en/password view
        .form-container {
            margin: 0;
            padding: 0;
            width: 100%;

            form {
                width: 100%;
            }

            .form-header {
                font-weight: 400;
                line-height: 1;
                margin: 0 0 2rem;
                text-align: left;
            }

            p {
                font-size: ms(-2);
                margin: 0 0 baseline(4) 0;
                padding: 0;
            }

            input {
                width: 100%;
            }

            .message-placeholder.action-success,
            .message-placeholder.action-error {
                background: $green;
                color: $white;
                font-size: ms(-3);
                margin-top: baseline(2);
                padding: baseline(2);
            }

            .message-placeholder.action-error {
                background: $red;
                color: $white;
                font-size: ms(-3);
                padding: baseline(2);
            }
        }
    }
}

@keyframes ripple-loader {
    from {
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }

    to {
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
}

@media (max-width: 600px) {
    .mod-calmradio-login {
        width: 96%;

        .mod-calmradio-login-header {
            padding: baseline(4) baseline(4) 0 baseline(4);
        }

        &.username-reminder,
        &.password-reminder {
            padding: baseline(4) baseline(4) baseline(20) baseline(4);

            button {
                margin-top: baseline(2);
            }
        }

        &.is-register {
            padding: baseline(4) 0 0 0;

            .mod-calmradio-login-header {
                padding: 0 baseline(4);
            }

            #register_form {
                padding: 0 baseline(4) baseline(26) baseline(4);
            }

            .checkbox label {
                display: block;
            }

            .mod-calmradio-login-social-disclaimer {
                bottom: baseline(-12);
            }

            .mod-calmradio-login-register {
                bottom: baseline(-20);
            }
        }
    
        #login_form {
            padding: 0 baseline(4) baseline(26) baseline(4);

            .grecaptcha-badge {
                bottom: 192px!important;
            }
        }

        .mod-calmradio-login-social {
            flex-wrap: wrap;

            .mod-calmradio-login-with-fb, 
            .mod-calmradio-login-with-google {
                width: 100%;
            }
        }

        .mod-calmradio-login-links {
            flex-wrap: wrap;

            & > a,
            & > div {
                width: 100%;
            }

            & > a {
                padding-top: 1rem;
            }
        }
    }

    body .mod-login.password-retrieval {
        padding: baseline(4);
        width: 96%;
    }
}

@media (max-height: 900px) {
    .mod-calmradio-login,
    .mod-calmradio-login.username-reminder,
    .mod-calmradio-login.password-reminder,
    .mod-calmradio-login.is-hidden,
    .mod-calmradio-login.username-reminder.is-hidden,
    .mod-calmradio-login.password-reminder.is-hidden {
        position: absolute;
        top: 120px;
        transform: translateX(-50%);
    }
}
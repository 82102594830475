// Buttons -----------------------------------------------------------------
.btn,
.pagination-prev a,
.pagination-next a {
    background: $dark;
    border: 1px solid $dark;
    border-radius: 2px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font: $font-weight-semibold ms(-6) $font-base;
    letter-spacing: 0.05em;
    padding: baseline(3) baseline(5.5);
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.26s ease;
    width: 100%;

    @include mappy-bp(sm) {
        width: auto
    }

    &:hover {
        background: tint($dark, 15%);
        border-color: tint($dark, 15%);
        color: $white;
        text-decoration: none;
    }

    &:focus {
        color: $white;
        outline: none;
        text-decoration: none;
    }

    &:active {
        box-shadow: 1px 2px 2px rgba(black, 0.2);
        color: $white;
        opacity: 1;
        text-decoration: none;
    }

    &-white {
        background: white;
        border-color: white;
        color: $dark;

        &:hover {
            background: $brown;
            border-color: $brown;
            color: white;
        }
    }

    &-brown {
        background: $brown;
        border-color: $brown;

        &:hover {
            background: shade($brown, 15%);
            border-color: shade($brown, 15%);
        }
    }

    &-green {
        background: $green;
        border-color: $green;

        &:hover {
            background: shade($green, 15%);
            border-color: shade($green, 15%);
        }
    }

    &-outline {
        background: none;
        border-color: $gray-middle;
        color: $dark;

        &:hover {
            background: none;
            border-color: $dark;
            color: $dark;
            opacity: 1;
        }
    }


    &.is-disabled,
    &:disabled {
        background-color: $gray-light;
        border-color: $gray-light;
        color: $gray;
        cursor: not-allowed;
    }
}

[type=button],
[type=submit],
button {
    @extend .btn;
    @include appearance-none;
}

.pagination-prev a,
.pagination-next a {
    font-size: 1.6rem;
    font-weight: 300;
    padding: baseline(3) baseline(5.5);
}

.mod-calmradio-login-button {
    border: none!important;
}

.pricing-page {
    background: $white;

    #plans_container {
        background: $gray-light;

        .pricing-table-wrapper {
            padding: baseline(20) 0;
            margin: 0 auto;
            max-width: $page;
            width: 100%;

            .pricing-table-col:first-child {
                background: $white;
            }
        }
    }

    .pricing-page-faq-wrapper {
        margin: baseline(-20) auto 0 auto;
        max-width: calc(#{$page} * 0.7);
        padding: 0 0 baseline(40) 0;
        width: 100%;


        .pricing-faq {
            display: flex;
            flex-wrap: wrap;

            &-item {
                padding-bottom: baseline(10);
                width: 50%;

                &:nth-child(odd) {
                    padding-right: baseline(10);
                }

                &:nth-child(even) {
                    padding-left: baseline(10);
                }

                &-title {
                    font-size: ms(5);
                }

                &-content {
                    font-size: ms(-2);
                }
            }
        }
    }
}
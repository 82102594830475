/**
 * System page - related CSS
 */
.system-page-wrapper {
    background: $white;
}

.system-page-header {
    h1 {
        margin-bottom: baseline(20);
    }
}

/**
 * Landing page - related CSS
 */
.landing-page {
    &-header {
        &-title {
            text-align: center;
        }
    }

    &-content {
        margin: 0 auto;
        max-width: 90%;
        padding: baseline(20) 0 baseline(80) 0;
        position: relative;
        width: $blog-entry;

        blockquote {
            color: $white;
            font-size: ms(5);
            margin-bottom: baseline(12);

            &:before,
            &:after {
                display: none;
            }

            cite {
                color: $white;
                display: block;
                font-family: $font-decorative;
                margin-top: baseline(5);
                text-align: center;

                &:before {
                    content: "";
                }
            }
        }
    }
}

@media (max-width: 1240px) {
    .landing-page {
        .page-header .wrapper {
            max-height: 50vh;
        }

        .landing-page-content {
            padding-top: 0;
            padding-bottom: baseline(20);
        }
    }
}

@media (max-width: 800px) {
    .landing-page {
        .page-header .wrapper {
            max-height: 40vh;
        }

        .landing-page-content {
            padding-bottom: baseline(10);
        }
    }
}
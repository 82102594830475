.testimonials {
    &-title {
        font-family: $font-base;
        font-size: ms(0);
        margin: 0;
        text-align: center;
    }

    &-wrapper {
        padding: baseline(36) 0;
    }

    &-item {
        margin: baseline(10) auto;
        max-width: $page-medium * 0.75;
        width: 100%;

        &:before {
            display: none;
        }

        &-text {
            font-size: ms(5);

            &:before {
                content: open-quote;
                margin-right: -5px;
            }

            &:after {
                content: close-quote;
                margin-left: -5px;
            }
        }

        &-author {
            display: block;
            font-family: $font-decorative;
            margin-top: baseline(5);
            text-align: center;
        }
    }
}
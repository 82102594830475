.heading-frontpage.has-top-promo {
    .cr-player {
        height: calc(100% - 35px);
    }

    .top-promotions {
        background: rgba(235,235,235,0.2)!important;
    }
}

.cr-player {
    height: 100%;
    left: 50%;
    max-width: 100%;
    opacity: 1;
    padding-top: 175px;
    position: absolute;
    transform: translateX(-50%);
    transition: opacity 1s ease-out;
    width: 1600px;
    will-change: opacity;
    z-index: 10;

    &-album {
        margin: 0 auto;
        position: absolute;
        top: 50%;
        transform: translateY(-60%);
        transition: opacity .25s ease-out;
        width: 100%;
        will-change: opacity;
        z-index: 10;

        &.is-transparent {
            opacity: .25;
        }

        &-cover {
            background: $dark;
            margin: 0 auto;
            opacity: 1;
            position: relative;
            transition: all .25s ease-out;
            width: 600px;
            z-index: 2;

            &-image {
                box-shadow: 0 -20px 50px rgba(0, 0, 0, .25);
                display: block;
                height: 600px;
                margin: 0 auto;
                opacity: 0;
                overflow: hidden;
                position: relative;
                width: auto;
                z-index: 2;

                &-replacement {
                    display: block;
                    height: 600px;
                    left: 50%;
                    margin: 0 auto;
                    opacity: 1;
                    overflow: hidden;
                    position: absolute;
                    transition: all .5s ease-out;
                    transform: translateX(-50%);
                    top: 0;
                    width: auto;
                    z-index: 3;

                    &.is-hidden {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }

            &.is-visible {
                .cr-player-album-cover-image {
                    opacity: 1;
                }
            }
        }

        &-loader {
            background: $dark;
            height: 600px;
            left: 50%;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            width: 600px;
            z-index: 1;

            svg {
                display: none!important;
            }

            &:before {
                animation: content-loader 0.8s ease-in-out infinite;
                border-radius: 50%;
                border: 1px solid $white;
                border-top-color: transparent;
                content: '';
                height: 64px;
                left: 50%;
                margin: -32px 0 0 -32px;
                position: absolute;
                top: 50%;
                width: 64px;
            }
        }

        &-fav,
        &-like,
        &-dislike {
            align-items: center;
            appearance: none;
            border: none;
            background: rgba(0,0,0,0.2);
            display: flex;
            height: 36px;
            justify-content: center;
            padding: 0;
            transition: all .5s ease-out;
            width: 36px;
            z-index: 4;

            & > svg {
                fill: $white;
                opacity: .9;
            }

            &:active,
            &:focus,
            &:hover {
                background: $darkest!important;
                transform: none;

                & > svg {
                    fill: $brown-light;
                    opacity: 1;
                }
            }
        }

        &-fav {
            display: inline-flex;
            margin-left: 10px;
            margin-top: -3px;

            & > svg {
                position: relative;
                top: 1px;
            }

            &.is-favorited {
                background: $brown!important;

                & > svg {
                    fill: $white;
                    opacity: 1;
                }
            }
        }

        &-like {
            & > svg {
                position: relative;
                top: 1px;
            }

            &.is-liked {
                background: $brown!important;
                
                & > svg {
                    cursor: auto;
                    fill: $white;
                    opacity: 1;
                }
            }
        }

        &-dislike {
            & > svg {
                position: relative;
                top: 1px;
            }

            &.is-disliked {
                background: $brown!important;
                
                & > svg {
                    cursor: auto;
                    fill: $white;
                    opacity: 1;
                }
            }
        }

        &-info {
            align-items: center;
            background: $dark;
            display: flex;
            margin: 0 auto;
            padding: 32px 40px;
            width: 600px;

            &-text {
                max-height: 100px;               
                width: 65%;

                & > span,
                & > strong {
                    color: $white;
                    display: block;
                    font-family: $font-serif;
                    line-height: 1.4;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                & > strong {
                    font-size: ms(1);                    
                }                
                
                & > span {
                    color: $brown-light;
                    font-size: ms(-2);                   
                }
            }

            &-title {
                &.is-hidden {
                    display: none;
                }
            }

            &-chapters {
                &.is-hidden {
                    display: none;
                }

                .channel-chapters {
                    max-width: 80%;
                    position: relative;

                    &-ui {
                        display: none;
                    }
            
                    &-list {
                        margin: 0;
                        padding: 10px 0;
            
                        &-wrapper {
                            background: $dark-light;
                            border-radius: 0 0 3px 3px;
                            height: auto;
                            max-height: 200px;
                            overflow: auto;
                            position: absolute;
                            top: 100%;
                            width: 100%;
                            z-index: 2;
            
                            &.is-closed {
                                display: none;
                            }
                        }
                    }
            
                    &-item {
                        color: $gray-lighter;
                        font-size: ms(-3);
            
                        a {
                            color: $gray-lighter;
                            font-family: $font-serif;
                            height: 30px;
                            line-height: 31px;
                            overflow: hidden;
                            padding: 0 10px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
            
                            &:active,
                            &:focus,
                            &:hover {
                                color: $white;
                                text-decoration: none;
                            }
            
                            & > span {
                                pointer-events: none;
                            }
                        }
            
                        &.is-current {
                            a {
                                color: $white;
                                font-weight: 400;
                            }
                        }
                    }
            
                    &-current-item {
                        background: transparent;
                        border-radius: 3px;
                        border-bottom: 1px solid lighten($dark-light, 10%);
                        color: $brown-light;
                        cursor: pointer;
                        font-family: $font-serif;
                        font-size: ms(-1);
                        height: 30px;
                        line-height: 31px;
                        overflow: hidden;
                        margin-bottom: 0;
                        padding: 0 30px 0 0;
                        position: relative;
                        text-overflow: ellipsis;
                        user-select: none;
                        white-space: nowrap;
            
                        &:after {
                            border: 1px solid $brown-light;
                            border-top-color: transparent;
                            border-right-color: transparent;
                            content: "";
                            height: 10px;
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            transform: translateY(-50%) scaleY(.75) rotate(-45deg);
                            width: 10px;
                        }
                    }
                }
            }

            &-buttons {
                align-items: center;
                display: flex;     
                margin-left: auto;           

                button {
                    background: #000;
                    border-radius: 50%;
                    margin: 0 0 0 12px;

                    &:first-child {
                        margin: 0;
                    }

                    svg {
                        height: 20px;
                        width: 20px;
                    }

                    &:active,
                    &:focus,
                    &:hover {
                        background: $brown;
                    }
                }
            }

            p {
                color: $white;
                font-size: 13px;
                font-weight: 400;
                margin: 0;
                text-align: center;
                text-shadow: none;
                -webkit-font-smoothing: subpixel-antialiased;

                strong {
                    display: inline-block;
                    margin-bottom: 5px;

                    &:empty {
                        & + button {
                            opacity: 0;
                        }
                    }
                }

                &:first-child {
                    font-size: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }

    &-play,
    &-share,
    &-sleep,
    &-bitrate,
    &-fullscreen,
    &-switch {
        align-items: center;
        appearance: none;
        background: transparent;
        border: 1px solid rgba(255, 255, 255, .6);
        border-radius: 50%;
        display: flex;
        height: 36px;
        justify-content: center;
        left: 50%;
        margin-left: -18px;
        padding: 0;
        position: absolute;
        width: 36px;

        &:active,
        &:focus,
        &:hover {
            background: rgba(255, 255, 255, .125);
            border-color: $white;
            transform: none;
        }

        & > svg {
            fill: $white;
        }
    }

    &-sleep {
        margin-left: -64px;
    }

    &-audio {
        display: none;
    }

    &-play {
        border: 1px solid $white;
        height: 80px;
        margin-left: -40px;
        top: 0;
        width: 80px;

        svg {
            stroke: $white;
            transform: translateX(2px) scale(1.325);

            &.is-hidden {
                display: none;
                visibility: hidden;
            }

            &.cr-player-play-icon-pause {
                transform: translateX(0) scale(1.325);
            }
        }

        &.is-loading {
            animation: content-loader 0.8s ease-in-out infinite;
            border-top-color: transparent;

            svg {
                display: none!important;
            }
        }
    }

    &-fullscreen {
        margin-left: 74px;
    }

    &-share-popup,
    &-sleep-popup,
    &-bitrate-popup {
        bottom: 35px;
        left: 50%;
        opacity: 1;
        padding-bottom: 20px;
        position: absolute;
        transform: translateX(-50%) translateY(0);
        transition: all .25s ease-out;
        width: 230px;

        &-content {
            background: $dark;
            border-radius: $border-radius-small / 2;
            color: $white;
            font-size: 16px;
            font-weight: 300;
            padding: 25px;
            position: relative;
            width: 230px;

            &:after {
                border: 10px solid $dark;
                border-left-color: transparent;
                border-right-color: transparent;
                border-bottom-color: transparent;
                bottom: -20px;
                content: "";
                height: 20px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                width: 20px;
            }
        }

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
            transform: translateX(-50%) translateY(-20px);
            visibility: hidden;
        }

        strong {
            display: block;
            font-family: $font-serif;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 10px;
        }

        p {
            border-radius: $border-radius-small / 2;
            color: $brown-light;
            cursor: pointer;
            font-size: 14px;
            font-weight: 300;
            margin: 6px -10px;
            padding: 6px 10px 6px 45px;
            position: relative;
            transition: all .25s ease-out;

            &:hover {
                background: rgba(255, 255, 255, .05);
            }

            svg {
                fill: $brown-light;
                left: 14px;
                position: absolute;
                top: 48%;
                transform: translateY(-50%);
            }

            & > .cr-player-sleep-in,
            & > .cr-player-bitrate-in {
                border: 1px solid $brown-light;
                border-radius: $border-radius-full;
                display: block;
                height: 18px;
                left: 12.5px;
                position: absolute;
                top: 8px;
                width: 18px;

                &.is-active {
                    &:after {
                        background: $brown-light;
                        border-radius: $border-radius-full;
                        content: "";
                        height: 10px;
                        left: 3px;
                        position: absolute;
                        top: 3px;
                        width: 10px;
                    }
                }
            }
        }
    }

    &-sleep-popup {
        margin-left: -46px;
    }

    &-share {
        left: auto;
        right: 92px;
        top: 0;

        &-popup {
            left: -75px;
        }
    }

    &-switch {
        border: none;
        left: calc(50% + 60px);
        top: 24px;

        &-prev {
            left: calc(50% - 60px);
        }

        &.is-inactive {
            opacity: 0;
            pointer-events: none;
        }
    }

    &-ui {
        bottom: 60px;
        height: 60px;
        left: 50%;
        margin: 0 auto;
        position: absolute;
        transform: translateX(-50%);
        width: 600px;
        z-index: 5;
    }

    &-external-ui {
        bottom: 60px;
        height: 36px;
        opacity: 1;
        position: absolute;
        right: 130px;
        transition: opacity 1s ease-out;
        width: 36px;
        will-change: opacity;
        z-index: 10;
    }

    &-ui,
    &-external-ui {
        opacity: 0;
        pointer-events: none;
        transition: opacity .5s ease-out;
        will-change: opacity;

        &.is-active {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &-volume {
        bottom: 72px;
        left: 54px;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transition: opacity .5s ease-out;
        will-change: opacity;
        z-index: 11;

        &.is-active {
            opacity: 1;
            pointer-events: auto;
        }

        .rangeslider {
            background: $white;
        }

        .rangeslider--horizontal {
            height: 2px;
            width: 200px;
        }

        .rangeslider--horizontal .rangeslider__handle {
            top: -8px;
        }
    }

    &-email-sharing {
        background: rgba(0, 0, 0, .66);
        height: 100%;
        left: 0;
        opacity: 1;
        pointer-events: auto;
        position: fixed;
        top: 0;
        transition: all .25s ease-out;
        width: 100vw;
        z-index: 1000;

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
            visibility: hidden;

            .cr-player-email-sharing-form {
                transform: translateX(-50%) translateY(-50%);
            }
        }

        &-form {
            background: $white;
            left: 50%;
            padding: 50px;
            position: absolute;
            top: 50%;
            transform: translateX(-50%) translateY(-40%);
            transition: all .25s ease-out;
            width: 640px;

            h2 {
                font-size: 32px;
                margin: 0 0 20px 0;
            }

            .btn-send {
                float: right;
            }

            .cr-player-email-sharing-track-info {
                overflow: hidden;
                padding: 20px 0 35px 0;

                dt {
                    float: left;
                    margin-right: 10px;

                    img {
                        height: 64px;
                        width: 64px;
                    }
                }
            }

            .error-message {
                color: $color-danger;
                display: block;
                font-size: 12px;
                text-align: right;

                &.is-hidden {
                    display: none;
                    visibility: hidden;
                }
            }
        }
    }

    &-loading-error {
        align-content: center;
        background: rgba(0, 0, 0, .85);
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 99;

        &.is-hidden {
            display: none;
        }

        &-wrapper {
            align-content: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &-heading {
            color: $white;
            display: block;
            font-weight: normal;
            margin: 0 0 3rem 0;
            text-align: center;
            width: 100%;
        }
    }

    &-seeking-bar {
        cursor: pointer;
        display: none;
        height: 24px;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        width: 200px;

        &:after {
            background: rgba(255, 255, 255, .5);
            border-radius: 2px;
            content: '';
            height: 4px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }

        &-progress {
            background: $white;
            border-radius: 2px;
            height: 4px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: auto;
        }
    }

    &-ui.is-in-story-mode {
        .cr-player-seeking-bar {
            display: block;
            left: 0;
            pointer-events: auto;
            top: -40px;
            transform: none;
            width: 100%;
        }
    }
}

@keyframes content-loader {
    to {
        transform: rotate(360deg);
    }
}

@media (min-width: 1040px) {
    .heading-frontpage.hide-ui {
        .cr-player,
        .cr-player-external-ui,
        .cr-player-volume,
        .heading-configure-button {
            opacity: 0!important;
        }
    }
}

@media (max-width: 1280px) {
    .cr-player-ui {
        width: 450px;
    }
}

@media (max-width: 1040px) {
    .cr-player-ui {
        left: 60px;
        width: 84px;
    }

    .cr-player-volume {
        display: none;
    }

    .heading-configure-button {
        right: 64px;
    }

    .cr-player-external-ui {
        right: 110px;
    }

    .cr-player-msg-popup-close {
        right: 2px;
        top: 0;
    }

    .cr-player-bitrate-popup {
        left: auto;
        right: -98px;
        transform: translateY(0)!important;

        &.is-hidden {
            left: auto;
            right: 0;
            transform: translateY(-20px)!important;
        }

        &:after {
            left: auto;
            right: 0;
        }
    }

    .cr-player-switch-next,
    .cr-player-switch-prev,
    .cr-player-ui.is-in-story-mode .cr-player-seeking-bar {
        display: none;
    }
}

@media (max-width: 720px) {
    .cr-player-email-sharing-form {
        padding: 20px;
        max-width: 90%;
        transform: translateX(-50%) translateY(-50%);

        h2 {
            font-size: ms(4);
            margin-bottom: .5rem;
        }

        .cr-player-email-sharing-track-info {
            margin-top: 0;
            padding: 10px 0 15px;

            dd {
                font-size: 12px;
            }
        }

        .btn {
            width: 48%;
        }
    }
}

@media (max-width: 800px), (max-height: 800px) {
    .cr-player {    
        &-album {    
            &-cover {
                width: 280px;
    
                &-image {
                    height: 280px;
    
                    &-replacement {
                        height: 280px;
                    }
                }
            }
    
            &-loader {
                height: 280px;
                width: 280px;
            }
    
            &-info {
                flex-wrap: wrap;
                padding: 16px;
                width: 280px;

                &-text {
                    width: 100%;
                }

                &-buttons {
                    margin: -180px auto 0 auto;
                }
            }
        }
    }
}

@media (min-width: 800px) and (max-height: 1000px) {
    .cr-player {    
        &-album {
            &-cover {
                width: 500px;
    
                &-image {
                    height: 500px;
    
                    &-replacement {
                        height: 500px;
                    }
                }
            }
    
            &-loader {
                height: 500px;
                width: 500px;
            }

            &-info {
                width: 500px;
            }
        }
    }
}

@media (min-width: 800px) and (max-height: 900px) {
    .cr-player {    
        &-album {
            &-cover {
                width: 400px;
    
                &-image {
                    height: 400px;
    
                    &-replacement {
                        height: 400px;
                    }
                }
            }
    
            &-loader {
                height: 400px;
                width: 400px;
            }

            &-info {
                padding: baseline(3);
                width: 400px;
            }
        }
    }
}

@media (min-width: 800px) and (max-height: 800px) {
    .cr-player {    
        &-album {
            &-cover {
                width: 320px;
    
                &-image {
                    height: 320px;
    
                    &-replacement {
                        height: 320px;
                    }
                }
            }
    
            &-loader {
                height: 320px;
                width: 320px;
            }

            &-info {
                padding: baseline(3);
                width: 320px;
            }
        }
    }
}

@media (max-height: 860px) {
    .cr-player-album-info-chapters .channel-chapters-list-wrapper {
        max-height: 140px;
    }
}

@media (max-height: 640px) {
    .cr-player-album-info p {
        font-size: 11px;
    }

    .cr-player-album-info p:first-child {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .cr-player-album-info {
        margin-bottom: 10px;
    }
}

@media (max-height: 640px) {
    .cr-player-ui,
    .heading-configure-button,
    .cr-player-external-ui {
        bottom: 30px;
    }

    .cr-player {    
        &-album {    
            &-cover {
                width: 200px;
    
                &-image {
                    height: 200px;
    
                    &-replacement {
                        height: 200px;
                    }
                }
            }
    
            &-loader {
                height: 200px;
                width: 200px;
            }
    
            &-info {
                width: 200px;

                &-buttons {
                    margin: -180px auto 0 auto;
                }
            }
        }
    }
}

@media (max-height: 500px) {
    body.player-loaded {
        .heading {
            min-height: 500px;
        }
    }
}
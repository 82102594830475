.pricing-table {
    display: flex;
    justify-content: center;
    margin: baseline(15) auto;
    width: 100%;

    &-col {
        margin: 0 2rem;
        padding: baseline(8) baseline(10);
        width: 21.5%;

        &:first-child {
            background: $color-bg-light;
        }
    }

    &-title {
        font-family: $font-base;
        font-size: ms(-2);
        font-weight: 500;
        margin: 0 0 baseline(2) 0;
    }

    &-price {
        color: $dark;
        font-family: $font-serif;
        font-size: ms(15);
        font-weight: 400;
        line-height: 1;

        & > sub {
            bottom: 0;
            color: $gray;
            font-family: $font-base;
            font-size: ms(-4);
            top: 0;
        }
    }

    &-features {
        margin: baseline(8) 0;
    }

    &-feature {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='15px' height='11px' viewBox='0 0 15 11' %3e%3cpolygon points='13.636,0 5.455,8.25 1.364,4.125 0,5.5 4.091,9.625 5.455,11 15,1.375' fill='%231e1e1e'/%3e%3c/svg%3e ");
        background-position: left center;
        background-repeat: no-repeat;
        font-size: ms(-2);
        line-height: 1;
        padding: baseline(3) 0 baseline(3) baseline(6);

        &-inactive {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 11 11'%3e%3cpolygon points='11 9.63 6.88 5.5 11 1.38 9.63 0 5.5 4.13 1.38 0 0 1.38 4.13 5.5 0 9.63 1.38 11 5.5 6.88 9.63 11 11 9.63' fill='%23aaa'/%3e%3c/svg%3e");
        }
    }

    &-col {
        position: relative;

        &-additional-users {
            align-items: center;
            bottom: -1rem;
            display: flex;
            font-size: 0.8rem;
            height: 2rem;
            justify-content: flex-start;
            left: 0;
            line-height: 1.2;
            padding: 0 baseline(10);
            position: absolute;
            width: 100%;

            .btn-buy-additional {
                color: $color-text;
                position: relative;
                text-decoration: none;
            }

            &.active {
                .btn-buy-additional {
                    color: $gold;
                    margin-left: 20px;

                    &:before {
                        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='15px' height='11px' viewBox='0 0 15 11' %3e%3cpolygon points='13.636,0 5.455,8.25 1.364,4.125 0,5.5 4.091,9.625 5.455,11 15,1.375' fill='%23ca7e31'/%3e%3c/svg%3e ");
                        background-position: left center;
                        background-repeat: no-repeat;
                        content: "";
                        height: 20px;
                        left: -20px;
                        position: absolute;
                        top: -2px;
                        width: 20px;
                    }
                }
            }
        }
    }
    
    .pricing-table-col {
        .price-desc {
            margin: .5rem 0 0;
            font-size: .9rem;
            font-weight: bold;
            color: #ca7e31;
        }
    }
}

.pricing-faq {
    margin-left: 0;
}

.pricing-page .pricing-table-col {
    padding-bottom: baseline(16);

    &-additional-users {
        bottom: 1rem;
    }
}

@media (max-width: 1500px) {
    .pricing-table-col {
        width: 30%;
    }
}

@media (max-width: 1040px) {
    .frontpage-content-pricing .wrapper {
        padding: baseline(10) 0;
    }

    .pricing-table {    
        &-col {
            padding: baseline(4);
            width: 32%;
        }
    }

    .pricing-page {
        #plans_container,
        .pricing-testimonials,
        .pricing-page-faq {
            padding-left: baseline(5);
            padding-right: baseline(5);
        }
    }
}

@media (max-width: 800px) {
    .frontpage-content-pricing .wrapper {
        padding: baseline(15) baseline(2) baseline(0) baseline(2);
    }

    .pricing-table {  
        flex-wrap: wrap;

        &-col {
            margin-bottom: baseline(10);
            margin-left: auto;
            margin-right: auto;
            max-width: 280px;
            width: 50%;
        }
    }

    .pricing-page {
        .pricing-page-faq-wrapper {
            .pricing-faq-item:nth-child(odd),
            .pricing-faq-item:nth-child(even) {
                padding: 0 0 baseline(10) 0!important;
                width: 100%;
            }
        }
    }
}

@media (max-width: 600px) {
    .pricing-table {
        width: 280px;
        
        &-col {
            width: 100%;
        }
    }
}
.cr-player-msg-popup {
    background: $white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .125);
    left: 50%;
    overflow: hidden;
    padding: 80px 70px 60px 70px;
    position: fixed;
    text-align: center;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 900px;
    z-index: 1000000;

    &-msg {
        color: $color-text;
        font-size: 16px;
        font-weight: 300;
    }

    &-header {
        font-weight: 400;
    }

    .btn {
        margin-top: 50px;

        & + small {
            color: $gray-medium;
            display: block;
            font-size: 12px;
            padding: 15px 0 0 0;
            text-align: center;
        }
    }

    &-close {
        font-size: 32px;
        font-weight: 300;
        height: 32px;
        line-height: 1;
        position: absolute;
        right: 26px;
        text-decoration: none!important;
        top: 24px;
        width: 32px;
    }

    &.is-hidden {
        display: none;
    }

    &.to-display {
        opacity: 0;
        transform: translateX(-50%) translateY(-40%);
        will-change: opacity, transform;
    }

    &.to-hide {
        opacity: 0;
        transform: translateX(-50%) translateY(-40%);
        transition: opacity .5s ease-out, transform .35s ease-out;
        will-change: opacity, transform;
    }

    &.is-visible {
        opacity: 1;
        transform: translateX(-50%) translateY(-50%);
        transition: opacity .5s ease-out, transform .35s ease-out;
        will-change: opacity, transform;
    }
}

.cr-player-msg-popup-overlay {
    background: rgba(0, 0, 0, .75);
    bottom: 0;
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999999;

    &.is-hidden {
        display: none;
    }

    &.to-display {
        opacity: 0;
        transition: opacity .5s ease-out;
        will-change: opacity;
    }

    &.to-hide {
        opacity: 0;
        transition: opacity .35s ease-out;
        will-change: opacity;
    }

    &.is-visible {
        opacity: 1;
        will-change: opacity;
    }
}

@media (max-width: 1280px) {
    .cr-player-msg-popup {
        padding: 40px 70px 30px;
        width: 90%;
    }
}

@media (max-width: 560px) {
    .cr-player-msg-popup {
        padding: 40px 30px 30px;
        width: 90%;
    }
}

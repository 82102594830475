.calmchristian-channels-items {
    .channel-chapters {
        max-width: 40%;
        position: relative;

        &-title {
            color: $brown-light;
            display: block;
            font-family: $font-serif;
            font-size: ms(6);
            font-weight: $font-weight-normal;
            margin-bottom: baseline(4);
        }

        &-list {
            margin: 0;
            padding: 10px 0;

            &-wrapper {
                background: $dark-light;
                border-radius: 0 0 3px 3px;
                height: auto;
                max-height: 500px;
                overflow: auto;
                position: absolute;
                top: 100%;
                width: 100%;
                z-index: 2;

                &.is-closed {
                    display: none;
                }
            }
        }

        &-item {
            color: $gray-lighter;
            font-size: ms(1);

            a {
                color: $gray-lighter;
                height: 50px;
                line-height: 51px;
                overflow: hidden;
                padding: 0 32px;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:active,
                &:focus,
                &:hover {
                    color: $white;
                    text-decoration: none;
                }

                & > span {
                    pointer-events: none;
                }
            }

            &.is-current {
                a {
                    color: $white;
                    font-weight: 400;
                }
            }
        }

        &-current-item {
            background: $dark-light;
            border-radius: 3px;
            border-bottom: 1px solid lighten($dark-light, 10%);
            color: $gray-lighter;
            cursor: pointer;
            font-size: ms(1);
            height: 60px;
            line-height: 61px;
            overflow: hidden;
            margin-bottom: baseline(6);
            padding: 0 64px 0 32px;
            position: relative;
            text-overflow: ellipsis;
            user-select: none;
            white-space: nowrap;

            &:after {
                border: 1px solid $brown-light;
                border-top-color: transparent;
                border-right-color: transparent;
                content: "";
                height: 10px;
                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-50%) scaleY(.75) rotate(-45deg);
                width: 10px;
            }
        }
    }
}

@media (max-width: 1040px) {
    .calmchristian-channels-items {
        .channel-chapters {
            max-width: 100%;
            width: 100%;

            &-list {    
                &-wrapper {
                    max-height: 320px;
                }
            }
        }
    }
}
.main-nav {
    left: 0;
    position: absolute;
    top: baseline(9);
    width: 100%;
    z-index: 100;

    .wrapper-wide {
        align-items: center;
        display: flex;
        margin: 0 auto;
        padding: 0 baseline(12);
        width: 100%;

        &.has-only-right-menu {
            .nav.menu {
                margin-left: auto;
                margin-right: 0;
                top: 0;
            }
        }
    }

    .logo {
        background: url('../images/logo.svg?v=2');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        display: block;
        height: 76px;
        overflow: hidden;
        position: relative;
        text-indent: -999px;
        top: -3px;
        width: 220px;
    }

    .nav.menu {
        display: flex;
        list-style-type: none;
        margin: 0 0 0 auto;
        padding: 0;

        &:first-of-type {
            margin: 0 auto 0 baseline(6);
            position: relative;
            top: -3px;
        }

        li {
            padding: 0;

            a {
                background: transparent;
                color: $white;
                display: inline-block;
                font-size: ms(-2);
                font-weight: 500;
                padding: 0 baseline(2);
                position: relative;
                white-space: nowrap;

                &:active,
                &:focus,
                &:hover {
                    background: transparent;
                    text-decoration: none;

                    &:after {
                        background: $white;
                        bottom: 1px;
                        content: "";
                        height: 1px;
                        left: baseline(2);
                        position: absolute;
                        right: baseline(2);
                    }
                }

                &.btn-login,
                &.btn-try-us-for-free {
                    background: $dark;
                    font-size: ms(-6);
                    font-weight: 500;
                    margin-left: baseline(3);
                    margin-top: -4px;
                    padding: baseline(2) baseline(4);
                    text-transform: uppercase;

                    &:active,
                    &:focus,
                    &:hover {
                        background: tint($dark, 15%);

                        &:after {
                            display: none;
                        }
                    }
                }

                &.btn-try-us-for-free {
                    background: $brown;

                    &:active,
                    &:focus,
                    &:hover {
                        background: tint($dark, 15%);
                    }
                }
            }
        }
    }

    .language-switcher {
        order: 2;
        margin-left: 1rem;
        position: relative;

        > a {
            border: 1px solid rgba(255, 255, 255, .6);
            border-radius: 15px;
            background: transparent;
            color: $white;
            display: inline-block;
            font-size: ms(-5);
            font-weight: 400;
            padding: 2px baseline(2.5);
            position: relative;
            white-space: nowrap;

            &:active,
            &:focus,
            &:hover {
                background: transparent;
                text-decoration: none;
            }
        }

        .language-switcher-dropdown {

            background: $white;
            box-shadow: 0 3px 5px rgba(0, 0, 0, .25);
            display: none;
            font-size: ms(-3);
            list-style-type: none;
            margin: 0;
            padding: baseline(2) baseline(4);
            position: absolute;
            right: 0;
            top: 56px;
            width: 200px;
            z-index: 100;
    
            &.is-active {
                display: block;
            }
    
            &:before {
                    border: 5px solid $white;
                    border-left-color: transparent;
                    border-right-color: transparent;
                    border-top-color: transparent;
                    content: "";
                    height: 10px;
                    position: absolute;
                    right: 15px;
                    top: -10px;
                    width: 10px;
            }
    
            a {
                color: $gray-medium;
                display: block;
                padding: baseline(1) 0;
    
                &:active,
                &:focus,
                &:hover {
                    color: $color-text;
                }
            }
        }
    }

    .header-user-avatar {
        align-items: center;
        border: 1px solid rgba(255, 255, 255, .6);
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: center;
        margin-left: baseline(2);
        padding: 1px;
        position: relative;
        top: 2px;
        width: 40px;
        will-change: opacity;
        order: 1;

        span {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 50%;
            display: block;
            height: 30px;
            overflow: hidden;
            width: 30px;

            svg {
                fill: $white;
            }

            &.is-empty {
                height: 26px;
                left: 0;
                position: relative;
                width: 26px;
            }
        }

        &:active,
        &:focus,
        &:hover {
            border: 1px solid rgba(255, 255, 255, 1);
        }

        &-dropdown {
            background: $white;
            box-shadow: 0 3px 5px rgba(0, 0, 0, .25);
            display: none;
            font-size: ms(-3);
            list-style-type: none;
            margin: 0;
            padding: baseline(2) baseline(4);
            position: absolute;
            right: 0;
            top: 56px;
            width: 200px;
            z-index: 100;

            &.is-active {
                display: block;
            }

            &:before {
                border: 5px solid $white;
                border-left-color: transparent;
                border-right-color: transparent;
                border-top-color: transparent;
                content: "";
                height: 10px;
                position: absolute;
                right: 15px;
                top: -10px;
                width: 10px;
            }

            a {
                color: $gray-medium;
                display: block;
                padding: baseline(1) 0;

                &:active,
                &:focus,
                &:hover {
                    color: $color-text;
                }
            }
        }
    }


}

@media (max-width: 1720px) {
    .main-nav {
        .wrapper-wide {
            max-width: 100%;
            padding: 0 baseline(5);
            width: 100%;
        }
    }
}

@media (max-width: 1440px) {
    .main-nav {
        top: 2rem;
    }
}

@media (max-width: 960px) {
    .main-nav {
        .logo {
            top: -8px;
            width: 280px;
        }
    }
}

@media (max-width: 720px) {
    .main-nav {    
        .nav.menu {    
            li {
                display: none;

                &:nth-last-of-type(1),
                &:nth-last-of-type(2) {
                    display: list-item;
                }
            }
        }

        .logo {
            margin-right: 20px;
            top: -2px;
        }
    }
}

@media (max-width: 560px) {
    .main-nav .wrapper-wide {
        padding: 0 baseline(2);
    }
}

@media (max-width: 480px) {
    .main-nav {
        .nav.menu {
            li {
                a {
                    &.btn-login,
                    &.btn-try-us-for-free {
                        margin-left: baseline(3);
                        padding: baseline(2) baseline(2);
                    }
                }
            }
        }
    }
}
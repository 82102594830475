.contact-form {
    background: $white;
    left: 50%;
    padding: baseline(8) 0 0 0;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all .25s ease-out;
    visibility: visible;
    width: 600px;
    z-index: 1;

    .contact-form-header {
        padding: 0 baseline(8);
    }

    &-text-center {
        text-align: center;
    }

    #contact_form {
        overflow: hidden;
        padding: 0 baseline(8) baseline(8) baseline(8);
        position: relative;

        .grecaptcha-badge {
            bottom: 38px!important;
            position: absolute!important;
        }

        .nog-captcha-container {
            align-items: center;
            display: flex;
            margin-top: baseline(8);

            img {
                height: 46px;
                margin: 0 1rem;
            }

            &.loading {
                img {
                    opacity: .125;
                }
            }

            input.is-invalid {
                background: rgba(red($color-danger), green($color-danger), blue($color-danger), .1);
                border-bottom-color: $color-danger!important;
            }

            input.is-loading {
                opacity: .5;
                pointer-events: none;
            }

            .help-block.form-error {
                display: none;
            }
        }
    }

    &.is-hidden {
        opacity: 0;
        pointer-events: none;
        transform: translateX(-50%) translateY(-40%);
        visibility: hidden;
        z-index: 0;
    }

    &-message {
        background: $color-danger;
        color: $white;
        font-size: ms(-2);
        font-weight: 500;
        margin-top: baseline(2);
        padding: baseline(2) baseline(4);

        &.is-hidden {
            display: none;
            visibility: hidden;
        }
    }

    &-input {
        padding: baseline(4) 0 0;

        textarea {
            height: 150px;
            margin-bottom: baseline(4);
            resize: vertical;
        }
    }

    .nog-captcha-container {
        margin-top: 0!important;
        margin-bottom: baseline(8);
    }

    &-button {
        padding-left: baseline(4);
        padding-right: baseline(4);

        .contact-form-button-state-normal {
            display: inline;
        }

        .contact-form-button-state-loading {
            display: none;
        }

        &.is-loading {
            opacity: .5;
            pointer-events: none;

            .contact-form-button-state-normal {
                display: none;
            }

            .contact-form-button-state-loading {
                display: inline;
            }
        }
    }

    &-header {
        font-size: ms(5);
        font-weight: 400;
        line-height: 1;
        margin: 0 0 baseline(4) 0;
        text-align: left;
    }

    &-input {
        padding: baseline(4) 0 0 0;

        input.is-invalid,
        textarea.is-invalid {
            background: rgba(red($color-danger), green($color-danger), blue($color-danger), .1);
            border-bottom-color: $color-danger!important;
        }

        textarea {
            padding: baseline(2) 0;
        }

        input.is-loading,
        textarea.is-loading {
            opacity: .5;
            pointer-events: none;
        }
    }

    &-go-back {
        bottom: baseline(-12);
        font-size: ms(-2);
        left: 0;
        padding-bottom: baseline(2);
        position: absolute;
        text-align: center;
        width: 100%;

        a {
            color: $gold;

            &:active,
            &:focus,
            &:hover {
                color: $brown;
            }
        }
    }

    &-success {
        padding: baseline(4) baseline(8) baseline(12) baseline(8);

        &.is-hidden {
            display: none;
            visibility: hidden;
        }

        h2 {
            font-size: ms(5);
            font-weight: 400;
            line-height: 1;
            margin: 0 0 baseline(4) 0;
            text-align: center;
        }

        p {
            text-align: center;
        }
    }
}

@media (max-height: 900px) {
    .contact-form {
        position: relative;
        top: 120px;
        transform: translateX(-50%);

        &.is-success {
            top: 240px;
        }
    }
}

@media (max-width: 640px) {
    .contact-form {
        padding: baseline(4) 0 0 0;
        width: 95%;

        .contact-form-header {
            padding: 0 baseline(4);
        }
        
        #contact_form {
            padding: 0 baseline(4) baseline(4) baseline(4);
        }
    }
}

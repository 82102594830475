// Darken & Lighten color 

@function tint($color, $percentage) {
	@return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
	@return mix(black, $color, $percentage);
}

// Baseline for Vertical Rhythm

@function baseline($value){
  @return ($baseline) * $value
}



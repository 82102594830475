.blog {
    &-items {
        background: $white;
        padding: baseline(20) 0 baseline(40) 0;

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: calc(#{$page} + #{baseline(6)});
        }

        .nav.menu.mod-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin: -25px 0 25px 0;

            li {
                border-right: 1px solid $border-light;
                padding: 0 baseline(4);

                &:first-child {
                    padding-left: 2px;
                }

                &:last-child {
                    border-right: none;
                }

                a {
                    color: $color-text;

                    &:active,
                    &:focus,
                    &:hover {
                        color: $gold;
                    }
                }

                &.current {
                    font-weight: bold;

                    a {
                        color: $gold;
                    }
                }
            }
        }
    }

    &-item {
        margin: baseline(10) baseline(3);
        width: calc((100% / 3) - #{baseline(6)});

        &-title {
            margin-top: baseline(6.25);
            padding: 0 baseline(1);
            width: 100%;

            a {
                color: $color-text;
                display: block;
                font-size: ms(5);
            }
        }

        &-excerpt {
            font-size: ms(-2);
            padding: 0 baseline(1);
            width: 100%;
        }

        &-image {
            &-wrapper {
                border-radius: $border-radius-small / 2;
                display: block;
                overflow: hidden;

                & > img {
                    height: auto;
                    transition: all .25s ease-out;
                    width: 100%;
                }

                &:active,
                &:focus,
                &:hover {
                    img {
                        transform: scale(1.125);
                    }
                }
            }
        }
    }

    .pagination {
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        .counter {
            font-size: ms(-4);
            margin-top: baseline(2);
            order: 2;
            width: 100%;
        }

        & > ul {
            display: flex;
            justify-content: center;
            margin: baseline(2) 0 0 0;
            order: 1;
            width: 100%;

            li {
                margin: 0 baseline(1);
            }

            li:empty {
                display: none;
            }
        }
    }
}

@media (max-width: 1040px) {
    .blog,
    .featured-blog {
        &-items {
            background: $white;
            padding: baseline(10) 0 baseline(20) 0;
        }

        &-item,
        &-item-intro {
            margin: baseline(5) baseline(2)!important;
            width: calc((100% / 2) - #{baseline(4)})!important;
        }
    }
}

@media (max-width: 640px) {
    .blog,
    .featured-blog {
        &-item,
        &-item-intro {
            margin: baseline(6) 0!important;
            width: 100%!important;
        }
    }
}
.heading {
    background: $dark;
    height: 100vh;
    min-height: 450px;
    overflow: hidden;
    position: relative;

    &-frontpage {
        &-bg-image {
            height: 100%;
            object-fit: cover;
            pointer-events: none;
            width: 100%;
        }

        &.is-guest:after,
        &.is-guest:before {
            background-image: linear-gradient(
                to bottom,
                hsla(0, 0%, 0%, 0.66) 0%,
                hsla(0, 0%, 0%, 0.652) 8.1%,
                hsla(0, 0%, 0%, 0.628) 15.5%,
                hsla(0, 0%, 0%, 0.591) 22.5%,
                hsla(0, 0%, 0%, 0.544) 29%,
                hsla(0, 0%, 0%, 0.489) 35.3%,
                hsla(0, 0%, 0%, 0.428) 41.2%,
                hsla(0, 0%, 0%, 0.363) 47.1%,
                hsla(0, 0%, 0%, 0.297) 52.9%,
                hsla(0, 0%, 0%, 0.232) 58.8%,
                hsla(0, 0%, 0%, 0.171) 64.7%,
                hsla(0, 0%, 0%, 0.116) 71%,
                hsla(0, 0%, 0%, 0.069) 77.5%,
                hsla(0, 0%, 0%, 0.032) 84.5%,
                hsla(0, 0%, 0%, 0.008) 91.9%,
                hsla(0, 0%, 0%, 0) 100%
            );
            content: "";
            height: 50%;
            left: 0;
            position: absolute;
            pointer-events: none;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        &.is-guest:after {
            background-image: linear-gradient(
                to top,
                hsla(0, 0%, 0%, 0.66) 0%,
                hsla(0, 0%, 0%, 0.652) 8.1%,
                hsla(0, 0%, 0%, 0.628) 15.5%,
                hsla(0, 0%, 0%, 0.591) 22.5%,
                hsla(0, 0%, 0%, 0.544) 29%,
                hsla(0, 0%, 0%, 0.489) 35.3%,
                hsla(0, 0%, 0%, 0.428) 41.2%,
                hsla(0, 0%, 0%, 0.363) 47.1%,
                hsla(0, 0%, 0%, 0.297) 52.9%,
                hsla(0, 0%, 0%, 0.232) 58.8%,
                hsla(0, 0%, 0%, 0.171) 64.7%,
                hsla(0, 0%, 0%, 0.116) 71%,
                hsla(0, 0%, 0%, 0.069) 77.5%,
                hsla(0, 0%, 0%, 0.032) 84.5%,
                hsla(0, 0%, 0%, 0.008) 91.9%,
                hsla(0, 0%, 0%, 0) 100%
            );
            top: 50%;
        }

        &.is-loading:after {
            animation: loader 1s linear infinite;
            background: transparent;
            border: 1px solid $white;
            border-left-color: transparent;
            border-radius: 50%;
            content: "";
            height: 72px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(0deg);
            width: 72px;
        }

        .heading-overlay {
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: all 1s ease-out;
            width: 100%;
            will-change: opacity;
            z-index: 1;

            &:after,
            &:before {
                background-image: linear-gradient(
                    to bottom,
                    hsla(0, 0%, 0%, 0.66) 0%,
                    hsla(0, 0%, 0%, 0.652) 8.1%,
                    hsla(0, 0%, 0%, 0.628) 15.5%,
                    hsla(0, 0%, 0%, 0.591) 22.5%,
                    hsla(0, 0%, 0%, 0.544) 29%,
                    hsla(0, 0%, 0%, 0.489) 35.3%,
                    hsla(0, 0%, 0%, 0.428) 41.2%,
                    hsla(0, 0%, 0%, 0.363) 47.1%,
                    hsla(0, 0%, 0%, 0.297) 52.9%,
                    hsla(0, 0%, 0%, 0.232) 58.8%,
                    hsla(0, 0%, 0%, 0.171) 64.7%,
                    hsla(0, 0%, 0%, 0.116) 71%,
                    hsla(0, 0%, 0%, 0.069) 77.5%,
                    hsla(0, 0%, 0%, 0.032) 84.5%,
                    hsla(0, 0%, 0%, 0.008) 91.9%,
                    hsla(0, 0%, 0%, 0) 100%
                );
                content: "";
                height: 50%;
                left: 0;
                position: absolute;
                pointer-events: none;
                top: 0;
                width: 100%;
                z-index: 1;
            }

            &:after {
                background-image: linear-gradient(
                    to top,
                    hsla(0, 0%, 0%, 0.66) 0%,
                    hsla(0, 0%, 0%, 0.652) 8.1%,
                    hsla(0, 0%, 0%, 0.628) 15.5%,
                    hsla(0, 0%, 0%, 0.591) 22.5%,
                    hsla(0, 0%, 0%, 0.544) 29%,
                    hsla(0, 0%, 0%, 0.489) 35.3%,
                    hsla(0, 0%, 0%, 0.428) 41.2%,
                    hsla(0, 0%, 0%, 0.363) 47.1%,
                    hsla(0, 0%, 0%, 0.297) 52.9%,
                    hsla(0, 0%, 0%, 0.232) 58.8%,
                    hsla(0, 0%, 0%, 0.171) 64.7%,
                    hsla(0, 0%, 0%, 0.116) 71%,
                    hsla(0, 0%, 0%, 0.069) 77.5%,
                    hsla(0, 0%, 0%, 0.032) 84.5%,
                    hsla(0, 0%, 0%, 0.008) 91.9%,
                    hsla(0, 0%, 0%, 0) 100%
                );
                top: 50%;
            }
        }
    }

    .video-cover {
        bottom: 0;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        transition: opacity .75s ease-out;
        width: 100%;
        will-change: transform, opacity;

        video {
            height: auto;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            position: absolute;
            top: 50%;
            transform: translate(-50%,-50%);
            transition: opacity .75s ease-out;
            width: auto;
            will-change: opacity;

            &.is-hidden {
                opacity: 0;
            }
        }
    }

    &-content {
        bottom: 0;
        color: $white;
        display: flex;
        left: 0;
        position: absolute;
        right: 0;
        z-index: 2;

        &-player {
            align-items: center;
            background: $dark;
            display: flex;
            justify-content: flex-end;
            width: 35%;
        }

        &-play {
            cursor: pointer;
            font-family: $font-serif;
            padding-left: 120px;
            position: relative;
            width: 440px;

            &:before {               
                border: 1px solid $white;
                border-radius: 50%;
                content: "";               
                height: baseline(17.5);               
                left: 0;
                position: absolute;               
                top: baseline(-2.5);
                width: baseline(17.5);
            }
            
            &:after {
                background-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='utf-8'?%3e%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' xml:space='preserve'%3e%3cpolygon fill='%23FFFFFF' points='7,6 7,18 17,12 '/%3e%3c/svg%3e ");
                background-repeat: no-repeat;
                background-size: 28px 28px;
                background-position: 50% 50%;
                content: "";
                height: baseline(17.5);
                left: 0;
                position: absolute;                
                top: baseline(-2.5);
                transform: scale(1.325);
                width: baseline(17.5);
            }

            & > span {
                display: block;
                font-size: ms(4);
                line-height: 1.3;
            }

            & > strong {
                display: block;
                font-size: ms(4);
                font-weight: bold;
                line-height: 1.3;
            }

            &.is-loading {
                &:before {
                    animation: loader 1s linear infinite;
                    border-top-color: transparent; 
                    content: "";
                }
                
                &:after {
                     background: none;
                }
            }

            &.is-playing {
                &:after {
                    background-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='utf-8'?%3e%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' enable-background='new 0 0 24 24' xml:space='preserve'%3e%3cpath fill='%23FFFFFF' d='M7,6h3v12H7V6z M14,6v12h3V6H14z'/%3e%3c/svg%3e");                   
                }
            }
        }

        &-text {
            margin-left: 5%;
            width: 60%;

            & > h1 {
                color: $white;
                line-height: 1.125;
                max-width: 1000px;
            }

            & > p {
                margin-bottom: baseline(15);
                max-width: 1000px;
            }
        }
    }
}

@keyframes loader {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.page-header {
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: baseline(100);

        h1 {
            color: $white;
        }

        p {
            color: $gray-medium;
            margin: baseline(5) 0 baseline(20) 0;
        }
    }
}

@media (max-width: 1500px) {
    .heading-content-player {
        padding-left: baseline(10);
    }
}

@media (max-width: 1040px) {
    .heading {
        height: 90vh;
    }
}

@media (max-width: 980px) {
    .heading-content {
        flex-wrap: wrap;

        .heading-content-play {
            width: 250px;
        }

        .heading-content-player {
            justify-content: center;
            order: 2;
            padding: baseline(8) baseline(5);
            width: 100%;
        }

        .heading-content-text {
            margin: 0;
            order: 1;
            padding: baseline(5);
            width: 100%;

            & > p {
                margin: baseline(2) 0;
            }
        }
    }
}

@media (max-width: 720px) {
    .system-page-header,
    .landing-page-header {
        .wrapper {
            height: baseline(65);
        }
    }
}
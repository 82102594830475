html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: $dark;
    color: $gray;
    font-family: $font-base;
    font-weight: 400;
    line-height: 1.6;
}

*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

article,
aside,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    ul,
    ol {
        & > li {
            list-style: none;
            padding: 0;
        }
    }

    &.is-special-page {
        background: $dark url('../images/frontpage/enhance-banner-bg.jpg') no-repeat;
        background-size: auto;
        background-size: cover;
        height: 100%;
        min-height: 100vh;
        overflow: hidden;

        &:after {
            content: "";
            background: #000;
            height: 100%;
            left: 0;
            width: 100%;
            display: block;
            opacity: 0.7;
            position: fixed;
            top: 0;
        }
    }
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
}

.wrapper {
    margin: 0 auto;
    max-width: 100%;
    width: $page;
}

a {
    transition: all .25s ease-out;

    &.with-underline {
        text-decoration: underline;
    }
}

button,
input,
select,
textarea {
    font: inherit;
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="www"],
input[type="tel"] {
    background: transparent;
    border: none;
    border-bottom: 1px solid $border-medium;
    padding: baseline(1) 0;
    width: 100%;

    &:focus {
        outline: none;
        border-bottom-color: $gold;
    }

    &:disabled {
        background: $gray-light;
        cursor: not-allowed;
    }

    &.has-error {
        border-bottom-color: $color-danger;
    }
}

textarea {
    background: transparent;
    border: none;
    border-bottom: 1px solid $border-medium;
    width: 100%;

    &:focus {
        outline: none;
        border-bottom-color: $gold;
    }

    &.has-error {
        border-bottom-color: $color-danger;
    }
}

input[type="radio"],
input[type="checkbox"] {
    display: inline-block;
    height: 1.8rem;
    margin-right: 1rem;
    width: 1.8rem;
}

.checkbox {
    position: relative;

    input[type="checkbox"] {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        top: 0;
        z-index: 1;

        &:checked + label {
            &:before {
                background: $gold;
                border: none;
                content: "✓";
            }
        }
    }

    label {
        cursor: pointer;
        padding-left: baseline(8);
        position: relative;
        user-select: none;

        &:before {
            border: 1px solid $border;
            border-radius: 2px;
            color: $white;
            content: "";
            display: inline-block;
            font-size: 12px;
            font-weight: bold;
            left: 0;
            line-height: 19px;
            height: 20px;
            pointer-events: none;
            position: absolute;
            text-align: center;
            top: 0;
            width: 20px;
        }
    }

    &.is-invalid {
        label {
            color: $color-danger;

            &:before {
                border: 1px solid $color-danger;
            }
        }
    }

    &.is-loading {
        opacity: .5;
        pointer-events: none;
    }
}

.radio-button {
    position: relative;

    input[type="radio"] {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        top: .1rem;
        z-index: 1;

        &:checked + label {
            &:before {
                background: $blue;
                border: none;
                content: "";
            }

            &:after {
                background: $white;
                border-radius: 50%;
                content: "";
                left: .6rem;
                height: .6rem;
                position: absolute;
                top: .7rem;
                width: .6rem;
            }
        }
    }

    label {
        cursor: pointer;
        padding-left: 3.2rem;
        position: relative;
        user-select: none;

        &:before {
            border: 1px solid $border;
            border-radius: 50%;
            color: $white;
            content: "";
            display: inline-block;
            font-size: 1.4rem;
            font-weight: bold;
            left: 0;
            line-height: 1.6rem;
            height: 1.8rem;
            pointer-events: none;
            position: absolute;
            text-align: center;
            top: .1rem;
            width: 1.8rem;
        }
    }

    &.is-invalid {
        label {
            color: $color-danger;

            &:before {
                border: 1px solid $color-danger;
            }
        }
    }

    &.is-loading {
        opacity: .5;
        pointer-events: none;
    }
}

/**
 * Better accessibility on keyboard
 */ 
body[data-input-intent="keyboard"] {
    a,
    .btn,
    button,
    input[type="button"],
    input[type="submit"] {
        &:active,
        &:focus {
            outline: 2px dotted $gold;
        }
    }
}

/**
 * Hide joomla edit menus in articles
 */
div[itemtype="https://schema.org/Article"] > .icons {
    display: none;
}

@media (max-width: 1640px) {
    .wrapper {
        max-width: 100%;
        padding-left: baseline(5);
        padding-right: baseline(5);
        width: 100%;
    }
}

@media (max-height: 1000px) {
    body.is-special-page {
        min-height: 1000px;
        overflow: auto;
    }
}

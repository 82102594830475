.myaccount-page {
    &-wrapper {
        background: $gray-light;

        #overview {
            & > div {
                display: flex;
                width: 100%;

                & > .half-width {
                    width: 50%;
                }
            }
        }
    }

    .fa.fa-sign-out {
        color: $brown;
    }

    .moscribe-myprofile {
        margin: 0 auto;
        max-width: 1200px;
        padding: baseline(5) 0 baseline(20) 0;
        width: 1400px;

        .select-wrapper.half-width {
            float: left;

            & + .select-wrapper.half-width {
                margin-left: baseline(2);
            }
        }

        button.btn-small {
            height: auto!important;
            line-height: 1!important;
            position: relative;
            top: -10px;
        }

        #central-content {
            background: transparent;
            margin: 0 -20px;
            padding: 0;

            button {
                background: $dark!important;
                border: 1px solid $dark;
                border-radius: 2px;
                color: white;
                cursor: pointer;
                display: inline-block;
                font: $font-weight-semibold ms(-6) $font-base;
                height: auto;
                letter-spacing: 0.05em;
                min-height: none;
                padding: baseline(3) baseline(5.5);
                vertical-align: middle;
                text-align: center;
                text-transform: uppercase;
                transition: all 0.26s ease;
                width: 100%;

                @include mappy-bp(sm) {
                    width: auto
                }

                &:hover {
                    background: tint($dark, 15%)!important;
                    border-color: tint($dark, 15%);
                    color: $white;
                    text-decoration: none;
                }

                &:focus {
                    color: $white;
                    outline: none;
                    text-decoration: none;
                }

                &:active {
                    box-shadow: 1px 2px 2px rgba(black, 0.2);
                    color: $white;
                    opacity: 1;
                    text-decoration: none;
                }

                &:active,
                &:focus,
                &:hover {
                    transform: none!important;
                }
            }

            .my-invoices-block {
                .detail-list-header {
                    background-color: transparent;
                    border-bottom: 1px solid $border-medium;
                    color: $color-text;

                    li {
                        font-weight: bold;
                        text-align: center;
                    }
                }

                .detail-list {
                     background-color: transparent;
                     border-bottom: 1px solid $border-medium;

                     li {
                         font-weight: normal;
                         text-align: center;
                     }
                }
            }

            .account-overview-block {
                button {
                    margin-top: 90px;
                }
            }

            .account-details-block {
                .account-details-header {
                    background: $gray-light;

                    .trigger {
                        color: $gray;
                    }
                }

                .inline-form {
                    .input-box.input-inline {
                        text-align: right;

                        button {
                            float: none;
                        }
                    }
                }
            }

            .profile-photo-block {
                #avatar_form {
                    & + .clearfix {
                        padding: baseline(3) 0;
                        text-align: center;

                        button {
                            float: none;
                        }
                    }
                }

                #profile_photo_form {
                    border-color: $brown-light;
                }
            }

            #social_networks_settings {
                button {
                    .fa {
                        display: none;
                    }
                }
            }

            .my-invoices-block, 
            .my-purchase-history-block, 
            .my-subscriptions-block {
                & > div {
                    padding: baseline(10);
                }
            }

            #overview {
                label {
                    font-size: 13px;
                }

                .card-expiry-update {
                    .input-box {
                        margin-bottom: baseline(5);
                    }
                }
            }
        }

        .my-profile-block {
            padding: 0;

            & > div {
                background: $white;
                border: none;
                box-shadow: 0 -0.5rem 4rem rgba(0, 0, 0, .065);
                padding: baseline(10);
            }

            h4,
            h5 {
                border-bottom: none;
                color: $color-text;
                font-family: $font-serif;
                font-size: ms(4);
                font-weight: 400;
                margin: 0 0 2rem 0;
                padding: 0;
                text-transform: none;
            }

            h5 {
                margin-left: 32px;
            }

            &.password-manager-block {
                & > div {
                    button {
                        margin-top: 3rem;
                    }
                }
            }

            &.internet-radio-urls-block {
                clear: both;
            }
        }

        .my-subscriptions-block {
            li {
                border-top: 1px solid $border-light;
                font-weight: normal!important;
                text-align: right;

                &:first-child {
                    border-top: none;
                }

                strong {
                    float: left;
                }

                .item-label {
                    font-weight: bold!important;
                    margin-bottom: 5px;
                }
            }
        }

        .my-purchase-block {
            display: none;
        }

        .card-expiry-update,
        .edit-credit-cards-block {
            .btn-small {
                background: $blue;
                border: none;
                color: $white;
                cursor: pointer;
                display: inline-block;
                margin: .75rem 0;
                padding: 0 2rem;
                vertical-align: middle;
                text-align: center;
                text-decoration: none;
                transition: all 0.24s ease;
                will-change: transform;

                &:hover {
                    color: $white;
                    text-decoration: none;
                    transform: translateY(-2px);
                }
            }

            input,
            select,
            .select-wrapper {
                background: $white;
                border-left: none!important;
                border-right: none!important;
                border-top: none!important;
            }

            select {
                border-bottom: 1px solid $border-medium;
                border-radius: 0;
            }

            .select-wrapper {
                border-bottom: none!important;

                .form-error {
                    color: $color-danger;
                    display: inline-block;
                    font-size: ms(-10);
                    line-height: 1.4;
                    margin-top: 1rem;
                }
            }

            .empty-value-message {
                display: inline-block;
                margin-top: 5px;
                padding-left: 0!important;
            }
        }

        .internet-radio-urls-block {
            .download-btn {
                background: $blue!important;
                border: none!important;
                color: $white;
                cursor: pointer;
                display: inline-block;
                margin: .75rem 0!important;
                padding: .35rem 2rem!important;
                vertical-align: middle;
                text-align: center;
                text-decoration: none;
                transition: all 0.24s ease;
                will-change: transform;

                &:hover {
                    color: $white;
                    text-decoration: none;
                    transform: translateY(-2px);
                }
            }
        }

        .password-manager-block {
            .action-error {
                background: transparent!important;
                color: $color-danger;
            }

            .action-success {
                background: transparent!important;
                color: $green;
            }

            .loader {
                background: rgba(255,255,255,.75);

                .ripple {
                    animation: giftcards-loader 0.8s ease-in-out infinite;
                    background: none;
                    border-radius: 50%;
                    border: 3px solid rgba(0, 0, 0, .125);
                    border-top-color: $gray-medium;
                    content: '';
                    height: 64px;
                    margin: 0;
                    transform: translateX(-50%) translateY(-50%) rotate(0deg);
                    width: 64px;
                }
            }
        }

        .account-overview-block {
            padding-bottom: baseline(5);

            button {
                float: left;
                margin: 3rem 0 0 0;
                width: 50%;
            }

            & > div {
                min-height: auto;
                padding-bottom: baseline(36);

                .profile-info {
                    .user-info {
                        border: none;
                        margin: 0;
                        padding: 0;

                        small {
                            font-style: normal;
                            font-weight: 300;
                        }
                    }

                    & + ul {
                        margin-left: 145px!important;
                        margin-top: -75px!important;

                        small {
                            font-style: normal;
                            font-weight: 300;
                        }
                    }
                }
            }

            .my-profile-block {
                border: none;
                float: right;
                margin-top: 5rem;
                padding: 0;
            }
        }

        .my-profile-block {
            padding: baseline(2);
        }

        .my-subscriptions-block {
            & > div {
                padding: baseline(10)!important;
            }
        }

        .account-details-block {
            .account-heading {
                small {
                    margin-left: 30px;
                    margin-right: 30px;
                }
            }

            .fa-twitter,
            .fa-facebook {
                margin-left: 26px!important;
                opacity: .5;
                pointer-events: none;

                &.connected {
                    opacity: 1;
                }
            }
        }

        .my-profile-block {
            .fa-twitter,
            .fa-facebook {
                margin-left: 6px!important;
                opacity: .5;
                pointer-events: none;

                &.connected {
                    opacity: 1;
                }
            }

            &.internet-radio-urls-block {
                & > div {
                    width: 100%;
                }

                .internet-radio-urls-block-item {
                    align-items: center;
                    display: flex;
                    padding-right: 0!important;

                    .btn {
                        color: $white;
                        margin-left: baseline(2);

                        &:first-of-type {
                            margin-left: auto;
                        }
                    }
                }
            }
        }

        .my-subscriptions-block {
            & > div {
                min-height: auto!important;
            }

            ul {
                min-height: auto!important;
            }
        }

        #central-content {
            .password-manager-block {
                & > div {
                    min-height: auto;
                }

                p {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .recuring-button {
                background: transparent!important;
                border: none;
                border-radius: 0;
                color: $brown;
                margin-right: 0;
                padding: 0;
                text-transform: none;

                &:active,
                &:focus,
                &:hover {
                    background: transparent!important;
                    color: $brown-light;
                    text-decoration: underline;
                }
            }
        }

        .input-box:not(.input-inline) {
            input[type=text],
            input[type=password],
            input[type=email] {
                padding-left: 3rem!important;
            }

            &:after {
                left: 2px;
                top: 8px;
            }
        }

        .internet-radio-urls-block {
            .url-header {
                background: transparent;
                border: none;
                border-bottom: 1px solid $border-medium;
                padding-left: 0;
                padding-right: 0;

                .url-row-content {
                    margin-left: 0;
                    text-align: left;
                }
            }

            .url-row {
                border: none;
                border-bottom: 1px solid $border-light;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .my-subscriptions-block {
            .detail-list-item {
                background: none;
                border: none;
                margin-bottom: 2rem;
                padding: 0;

                .list-item-header {
                    border-bottom: 1px solid $border-medium;
                    color: $brown;

                    .item-status.green {
                        color: $green;
                    }

                    .item-status.red {
                        color: $red;
                    }
                }

                li {
                    text-align: left;
                }

                .item-label {
                    color: $color-text;
                    padding-right: 1rem;
                    width: auto;
                }
            }
        }

        .profile-photo-block {
            .upload-form-wrapper {
                background: $color-bg-light!important;
            }
        }

        .my-profile-block.account-heading {
            margin-bottom: 4rem;
            padding: 4rem 7rem;

            i {
                top: 32px;
            }

            button {
                background: $blue;
                right: 40px;
                top: 36px;

                &:active,
                &:focus,
                &:hover {
                    transform: translateY(-2px);
                }
            }
        }

        .social-networks-block {
            .my-profile-block.account-heading {
                border: none;
                border-bottom: 1px solid $border-light;
                margin: 0;
                padding: .75rem 0 0 2rem;

                h4 {
                    margin-left: 0;
                }

                i {
                    opacity: 1;
                    left: -6px;
                    top: 14px;
                }

                h5 {
                    margin: .35rem 0 1rem 2rem!important;
                }

                button {
                    right: 0;
                    top: 17px;
                }

                small {
                    margin-left: 2rem;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .account-details-block {
            form {
                input,
                select,
                .select-wrapper {
                    border-left: none!important;
                    border-right: none!important;
                    border-top: none!important;
                }

                .select-wrapper {
                    background: none;
                    border-bottom: none!important;
                }

                select {
                    background: none;
                    border-bottom: 1px solid $border-medium;
                    border-radius: 0;
                }
            }
        }

        .card-expiry-update {
            & > div {
                padding-bottom: baseline(20);
            }
        }
    }

    #internet_radio_urls {
        .clearfix {
            display: flex;

            .url-row-links {
                margin-left: auto;
            }
        }
    }

    .moscribe-myprofile .url-row .url-title {
        color: $brown;
    }

    .moscribe-myprofile .url-row.url-header .icon-wrapper i, 
    .moscribe-myprofile .url-row.url-header .icon-wrapper i.fa-download, 
    .moscribe-myprofile .url-row.url-header .icon-wrapper i.fa-envelope-o {
        border-color: $brown;
        color: $brown;
    }

    .moscribe-myprofile .url-row .icon-wrapper i.fa-download {
        border-color: $green;
        color: $green;
    }
}

@media (max-width: 1240px) {
    .myaccount-page {
        .moscribe-myprofile {
            max-width: calc(100% - 40px);
        }
    }

    .myaccount-page-wrapper {
        #overview {
            & > div {
                flex-wrap: wrap;

                & > .half-width {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 1040px) {
    .moscribe-myprofile {
        #central-content {
            .internet-radio-urls-block {
                .internet-radio-urls-block-item {
                    flex-wrap: wrap;

                    & > div {
                        margin-bottom: baseline(2);
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .moscribe-myprofile #central-content .user-info-wrapper {
        display: none!important;
    }

    .myaccount-page .moscribe-myprofile .my-profile-block > div,
    .myaccount-page .moscribe-myprofile .my-subscriptions-block > div,
    .myaccount-page .moscribe-myprofile #central-content .my-invoices-block > div, 
    .myaccount-page .moscribe-myprofile #central-content .my-purchase-history-block > div, 
    .myaccount-page .moscribe-myprofile #central-content .my-subscriptions-block > div {
        padding: baseline(4)!important;
    }

    .myaccount-page .moscribe-myprofile .account-overview-block > div {
        padding-bottom: baseline(26)!important;
    }

    .myaccount-page .moscribe-myprofile .card-expiry-update > div {
        padding-bottom: baseline(12)!important;
    }

    .myaccount-page .moscribe-myprofile #central-content .account-overview-block button,
    .myaccount-page .moscribe-myprofile .account-overview-block .my-profile-block {
        margin-top: 50px;
    }

    .myaccount-page .moscribe-myprofile .account-overview-block>div .profile-info+ul {
        margin-left: 0!important;
        margin-top: 0!important;
    }
}

@media (max-width: 600px) {
    .moscribe-myprofile .account-details-block .account-details-header .trigger {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }

    .myaccount-page .moscribe-myprofile .my-profile-block.internet-radio-urls-block .internet-radio-urls-block-item .btn + .btn {
        margin-left: 0;
        margin-top: 10px;
    }

    .moscribe-myprofile #central-content #overview .card-expiry-update button {
        background-color: #20abe2;
        float: none;
        margin-top: 20px;
        margin-bottom: -40px;
    }

    .myaccount-page #internet_radio_urls .clearfix {
        flex-wrap: wrap;
    }

    .moscribe-myprofile .url-row .url-row-content,
    .myaccount-page #internet_radio_urls .clearfix {
        width: 100%;
    }

    .myaccount-page #internet_radio_urls .clearfix {
        justify-content: center;
        margin: 20px 0 10px 0;
    }

    .moscribe-myprofile .url-row.url-header .url-row-links .icon-wrapper {
        margin: 0 5px!important;
    }

    .moscribe-myprofile #central-content .profile-photo-block button:first-child {
        margin-bottom: 10px;
        margin-left: 0;
    }

    .moscribe-myprofile .account-details-block > div .inline-form {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 480px) {
    .myaccount-page .moscribe-myprofile .select-wrapper.half-width + .select-wrapper.half-width {
        margin-left: 0!important;
    }
}
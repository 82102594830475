.post {
    background: $white;

    &-header {
        background: $dark;
        height: 65vh;
        overflow: hidden;
        position: relative;

        &:after,
        &:before {
            background-image: linear-gradient(
                to bottom,
                hsla(0, 0%, 0%, 0.66) 0%,
                hsla(0, 0%, 0%, 0.652) 8.1%,
                hsla(0, 0%, 0%, 0.628) 15.5%,
                hsla(0, 0%, 0%, 0.591) 22.5%,
                hsla(0, 0%, 0%, 0.544) 29%,
                hsla(0, 0%, 0%, 0.489) 35.3%,
                hsla(0, 0%, 0%, 0.428) 41.2%,
                hsla(0, 0%, 0%, 0.363) 47.1%,
                hsla(0, 0%, 0%, 0.297) 52.9%,
                hsla(0, 0%, 0%, 0.232) 58.8%,
                hsla(0, 0%, 0%, 0.171) 64.7%,
                hsla(0, 0%, 0%, 0.116) 71%,
                hsla(0, 0%, 0%, 0.069) 77.5%,
                hsla(0, 0%, 0%, 0.032) 84.5%,
                hsla(0, 0%, 0%, 0.008) 91.9%,
                hsla(0, 0%, 0%, 0) 100%
            );
            content: "";
            height: 50%;
            left: 0;
            position: absolute;
            pointer-events: none;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        &:after {
            background-image: linear-gradient(
                to top,
                hsla(0, 0%, 0%, 0.66) 0%,
                hsla(0, 0%, 0%, 0.652) 8.1%,
                hsla(0, 0%, 0%, 0.628) 15.5%,
                hsla(0, 0%, 0%, 0.591) 22.5%,
                hsla(0, 0%, 0%, 0.544) 29%,
                hsla(0, 0%, 0%, 0.489) 35.3%,
                hsla(0, 0%, 0%, 0.428) 41.2%,
                hsla(0, 0%, 0%, 0.363) 47.1%,
                hsla(0, 0%, 0%, 0.297) 52.9%,
                hsla(0, 0%, 0%, 0.232) 58.8%,
                hsla(0, 0%, 0%, 0.171) 64.7%,
                hsla(0, 0%, 0%, 0.116) 71%,
                hsla(0, 0%, 0%, 0.069) 77.5%,
                hsla(0, 0%, 0%, 0.032) 84.5%,
                hsla(0, 0%, 0%, 0.008) 91.9%,
                hsla(0, 0%, 0%, 0) 100%
            );
            top: 50%;
        }

        &-wrapper {
            height: 65vh;
            margin: 0 auto;
            max-width: $page;
            position: relative;
            z-index: 2;
        }

        &-info {
            bottom: baseline(20);
            left: 0;
            position: absolute;
            width: 100%;
            z-index: 2;
        }

        &-title {
            color: $white;
            font-weight: 100;
            margin: 0 0 baseline(2) 0;
            text-shadow: 0 0 1px rgba(0, 0, 0, .5);
        }

        &-category,
        &-date,
        &-author {
            color: $white;
            display: block;
            font-size: ms(-2);
            opacity: 0.9;
            text-shadow: 0 0 1px rgba(0, 0, 0, .5);
        }

        &-overlay {
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            will-change: transform, opacity;
            z-index: 0;
        }
    }

    &-content {        
        margin: 0 auto;
        padding: baseline(20) 0 baseline(40) 0;
        position: relative;
        width: $blog-entry;

        & > img,
        & > figure,
        & > blockquote {
            margin: baseline(10) baseline(-20);
            width: calc(100% + baseline(40));
        }

        & > blockquote {
            margin: baseline(30) baseline(-20) baseline(20) baseline(-20);

            em {
                font-family: $font-decorative;
                font-size: ms(5);
            }
        }

        & ul li { 
            list-style: inherit; 
            padding: 0 0 baseline(1) baseline(4); 
        }

        & > figure {
            margin: baseline(6) baseline(-20) baseline(16) baseline(-20);

            figcaption {
                color: $color-text-light;
                font-size: ms(-3);
                font-style: italic;
                padding: baseline(4) 0 0 0;
                text-align: center;
            }
        }

        & > img {
            margin: baseline(6) baseline(-20);
        }

        &-social {
            left: 0;
            position: static;
            top: 0;
            margin-top: baseline(10);            

            ul {
                display: flex;
                justify-content: center;
                list-style-type: none;
                margin: 0;
                padding: 0;
                width: 100%;

                li {
                    margin: 0 baseline(1.2);
                    
                    & > a {
                        align-items: center;
                        background-position: 50% 50%;
                        background-repeat: no-repeat;
                        background-size: 40% 40%;
                        border: 1px solid $border-medium;
                        border-radius: 50%;
                        display: flex;
                        height: 41px;
                        justify-content: center;
                        margin-bottom: baseline(3);
                        transition: all .25s ease-out;
                        width: 41px;

                        &[href*="facebook.com"] {
                            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20%20fill%3D%22%234a639a%22%20d%3D%22M30.234%200H1.766A1.766%201.766%200%200%200%200%201.766v28.468A1.766%201.766%200%200%200%201.766%2032h15.326V19.608h-4.17v-4.83h4.17v-3.56c0-4.134%202.524-6.385%206.21-6.385a34.216%2034.216%200%200%201%203.727.19v4.32h-2.557c-2.005%200-2.394.953-2.394%202.35v3.084h4.78l-.622%204.83h-4.16V32h8.154A1.766%201.766%200%200%200%2032%2030.234V1.766A1.766%201.766%200%200%200%2030.234%200z%22%20%2F%3E%3C%2Fsvg%3E');
                        }

                        &[href*="twitter.com"] {
                            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024.999%2020.316%22%3E%3Cpath%20fill%3D%22%235daeec%22%20d%3D%22M25%202.405a10.253%2010.253%200%200%201-2.947.808A5.144%205.144%200%200%200%2024.308.376%2010.27%2010.27%200%200%201%2021.05%201.62a5.134%205.134%200%200%200-8.74%204.68A14.56%2014.56%200%200%201%201.74.94a5.134%205.134%200%200%200%201.587%206.846%205.108%205.108%200%200%201-2.323-.642v.064a5.132%205.132%200%200%200%204.114%205.03%205.142%205.142%200%200%201-2.316.088%205.134%205.134%200%200%200%204.79%203.562%2010.29%2010.29%200%200%201-6.37%202.2A10.44%2010.44%200%200%201%200%2018.012a14.586%2014.586%200%200%200%2022.454-12.29q0-.333-.015-.662A10.423%2010.423%200%200%200%2025%202.405z%22%20%2F%3E%3C%2Fsvg%3E');
                        }

                        &[href*="google.com"] {
                            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22%23db4e42%22%20d%3D%22M30.234%200H1.766A1.766%201.766%200%200%200%200%201.766v28.468A1.766%201.766%200%200%200%201.766%2032h28.468A1.766%201.766%200%200%200%2032%2030.234V1.766A1.766%201.766%200%200%200%2030.234%200zM17.49%2021.48a8.2%208.2%200%200%201-8.964%202.388%208.32%208.32%200%201%201%208.25-14.107c-.68.747-1.37%201.485-2.11%202.176a5.84%205.84%200%200%200-4.913-.96%205.25%205.25%200%201%200%203.537%209.877%204.594%204.594%200%200%200%202.7-3.147c-1.588-.032-3.175-.012-4.763-.056%200-.944-.008-1.885%200-2.83%202.648%200%205.3-.01%207.95.013a9.392%209.392%200%200%201-1.688%206.646zm11.593-4.287l-2.374.012c-.01.795-.01%201.583-.016%202.377h-2.366c-.008-.794-.008-1.582-.016-2.373-.79-.01-1.584-.013-2.374-.017v-2.365c.79%200%201.58-.008%202.374-.016%200-.79.012-1.583.02-2.373h2.362c0%20.79.012%201.582.016%202.372.79.008%201.584.012%202.374.016v2.365z%22%20%2F%3E%3C%2Fsvg%3E');
                        }

                        &:active,
                        &:focus,
                        &:hover {
                            transform: scale(1.25);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1640px) {
    .post-header-info {
        padding: 0 baseline(5);
    }
}

@media (max-width: 1040px) {
    .post-content {
        max-width: 100%;
        padding: baseline(10) baseline(5);
    
        & > blockquote {
            margin: baseline(20) baseline(0) baseline(10) baseline(0);
        }
    }
}
// Links
@mixin links ($link, $hover, $active, $focus) {
    & {
        color: $link;
        text-decoration: none;
        transition: all 0.26s ease-out;
    }

    &:hover {
        color: $hover;
        text-decoration: underline;
        text-decoration-skip: ink;
    }

    &:active {
        color: $active;
    }

    &:focus {
        color: $focus;
        outline: none;
    }
}

// Horizontal and vertical centering helper
@mixin centerXY($horizontal: true, $vertical: true) {
    position: absolute;
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    @else if ($horizontal) {
        left: 50%;
        transform: translate(-50%, 0);
    }
    @else if ($vertical) {
        top: 50%;
        transform: translate(0, -50%);
    }
}

// Fluid typography
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
    & {
        @each $property in $properties {
            #{$property}: $min-value;
        }
        @media screen and (min-width: $min-vw) {
            @each $property in $properties {
                #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
        }
        @media screen and (min-width: $max-vw) {
            @each $property in $properties {
                #{$property}: $max-value;
            }
        }
    }
}

// Appearance property
@mixin appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
}

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

.error-page {
    &-content {
        background-image: url('../images/frontpage/enhance-banner-bg.jpg');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 640px;
        padding: baseline(50) 0;
        position: relative;

        h1,
        h2,
        h3 {
            color: $white;
            font-weight: 300;
            margin: 0;
            text-align: center;
        }

        h1 {
            font-size: 16rem;
        }

        h2 {
            font-size: 2.4rem;
            margin-top: 2rem;
        }

        h3 {
            font-size: 2rem;
            margin-top: 2rem;
            opacity: .5;
        }
    }

    &-info {
        position: relative;
        z-index: 1;
    }
}

@media (max-width: 720px) {
    .error-page {
        &-content {
            min-height: 480px;
            padding: 12rem 2rem;

            h1 {
                font-size: 8rem;
            }

            h2 {
                font-size: 1.8rem;
            }

            h3 {
                font-size: 1.6rem;
            }
        }
    }
}

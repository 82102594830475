/**
 * Activation message
 */

.activation-msg {
    &.activation-success {
        margin: baseline(60) auto baseline(60) auto;
        max-width: 90%;
        text-align: center;
        width: 720px;

        h4 {
            color: $white;
            @include fluid-type(font-size, $minScreen, $maxScreen, map-get($h1, min), map-get($h1, max));
            margin-bottom: baseline(10);
        }

        p {
            @include fluid-type(font-size, $minScreen, $maxScreen, map-get($font, min), map-get($font, max));
        }
    }
}

@media (max-width: 769px) {
    .activation-msg {
        &.activation-success {
            margin: baseline(30) auto baseline(30) auto;
        }
    }
}

/**
 * Connecting message
 */ 

.connecting-msg {
    margin: baseline(60) auto baseline(60) auto;
    max-width: 90%;
    text-align: center;
    width: 720px;

    h4 {
        color: $white;
        @include fluid-type(font-size, $minScreen, $maxScreen, map-get($h1, min), map-get($h1, max));
        margin-bottom: baseline(10);
    }

    p {
        @include fluid-type(font-size, $minScreen, $maxScreen, map-get($font, min), map-get($font, max));
    }

    .btn {
        margin-top: 3rem;
    }
}

@media (max-width: 769px) {
    .connecting-msg {
        margin: baseline(30) auto baseline(30) auto;
    }
}

/**
 * Subscription success
 */
.subscription-success {
    background: transparent!important;
    margin: baseline(60) auto baseline(60) auto;
    max-width: 90%;
    padding: 0!important;
    text-align: center;
    width: 720px;

    h4 {
        color: $white;
        @include fluid-type(font-size, $minScreen, $maxScreen, map-get($h1, min), map-get($h1, max));
        margin-bottom: baseline(10);
    }

    p {
        @include fluid-type(font-size, $minScreen, $maxScreen, map-get($font, min), map-get($font, max));
    }
}

@media (max-width: 769px) {
    .subscription-success {
        margin: baseline(30) auto baseline(30) auto;
    }
}
*,
*:before,
*:after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

article,
aside,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

li {
	list-style: none
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: top;
}

button,
input,
select,
textarea {
	font: inherit;
}

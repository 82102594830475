@font-face {
    font-family: 'Professor';
    src: local('Professor'),
        url('../assets/fonts/Professor.woff2') format('woff2'),
        url('../assets/fonts/Professor.woff') format('woff');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair Display';
    src: url('../fonts/playfair-display/PlayfairDisplay-Regular.woff2') format('woff2'),
        url('../fonts/playfair-display/PlayfairDisplay-Regular.woff2') format('woff');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display'),
        url('../fonts/playfair-display/PlayfairDisplay-Bold.woff2') format('woff2'),
        url('../fonts/playfair-display/PlayfairDisplay-Bold.woff2') format('woff');
    font-display: swap;
    font-weight: bold;
    font-style: normal;
}

// Typography
html {
    @include fluid-type(font-size, $minScreen, $maxScreen, map-get($font, min), map-get($font, max));
}

body {
    color: $gray;
    font-family: $font-base;
    font-weight: $font-weight-light;
    line-height: $line-height;
}

a {
    @include links ($gold, $dark, $dark, $dark);
}

p,
ul,
ol,
dl,
table,
hr,
pre {
    margin-top: baseline(7); 
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: $dark;
    font-family: $font-serif;
    font-weight: $font-weight-normal;
    line-height: 1.3;
    margin-top: baseline(10);
}

h1,
.h1 {
    @include fluid-type(font-size, $minScreen, $maxScreen, map-get($h1, min), map-get($h1, max));
    margin: 0;
}

h2,
.h2 {
    @include fluid-type(font-size, $minScreen, $maxScreen, map-get($h2, min), map-get($h2, max));
}

h3,
.h3 {
    @include fluid-type(font-size, $minScreen, $maxScreen, map-get($h3, min), map-get($h3, max));
}

h4,
.h4 {
    @include fluid-type(font-size, $minScreen, $maxScreen, map-get($h4, min), map-get($h4, max));
}

h5,
.h5 {
    @include fluid-type(font-size, $minScreen, $maxScreen, map-get($h5, min), map-get($h5, max));
}

h6,
.h6 {
    @include fluid-type(font-size, $minScreen, $maxScreen, map-get($h6, min), map-get($h6, max));
}

h2,
h3,
h4,
h5,
h6 {
    & + p {
        margin-top: baseline(4);
    }
}

b,
strong {
    font-weight: $font-weight-bold;
}

blockquote {
    color: $dark;
    font-family: $font-serif;
    line-height: 1.8;
    margin-top: baseline(26);
    margin-bottom: baseline(24);
    position: relative;
    text-align: center;
    @include fluid-type(font-size, $minScreen, $maxScreen, ms(1), ms(3));

    &::before {
        color: $brown;
        content: open-quote;
        font-family: $font-serif;
        font-size: 4.4rem;
        line-height: 0;
        top: baseline(-7);
        @include centerXY(true, false)
    }

    &.big {
        line-height: 1.6;
        @include fluid-type(font-size, $minScreen, $maxScreen, ms(2), ms(11));

        &::before {
            content: none;
        }

        & + figcaption {
            margin-top: baseline(-16);
        }
    }

    & + figcaption {
        color: $dark;
        font-family: $font-decorative;
        font-size: ms(4);
        margin-top: baseline(-18);
        margin-bottom: baseline(20);
        text-align: center;
    }

    & > :nth-child(1) {
        margin-top: 0;
    }
}

ul,
ol {
    margin-left: 2rem;

    & > li {
        list-style: inherit;
        padding: 0 0 baseline(1) baseline(4);
    }
}

dl {
    dt {
        font-weight: $font-weight-bold;
    }
}

pre {
    padding: baseline(6) baseline(12);
    white-space: pre-wrap;
    word-wrap: break-word;
}

code,
pre {
    background-color: $gray-light;
    font-family: $monospace;
    font-size: ms(-1);
}

sub,
sup {
    font-size: 65%;
}

hr {
    border: 0;
    height: 0;
    border-top: 1px solid $border;
    border-bottom: 1px solid rgba(white, 0.3);
}

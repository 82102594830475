.frontpage-block {
    &-app,
    &-enhance {
        background-color: $dark;
        height: 800px;
        position: relative;
        overflow: hidden;
        width: 100%;

        &-additional-text {
            color: $white;
            font-family: $font-decorative;
            font-size: ms(4);
            margin-left: baseline(8);
        }

        &-content {
            left: 55%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 500px;
            z-index: 1;
        }

        &-cta {
            &-wrapper {
                margin-top: baseline(10);
            }
        }

        &-title {
            color: $white;
            line-height: 1.2;
            margin: 0;
        }

        &-overlay {
            background-image: url('../images/frontpage/enhance-banner-bg.jpg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            height: 1000px;
            left: 0;
            top: 0;
            transform: translateY(-100px);
            width: 100%;
            will-change: transform;
            z-index: 0;
        }
    }

    &-app {
        .wrapper {
            height: 800px;
            position: relative;
            z-index: 1;
        }

        &-content {
            left: 0;
            width: 640px;
        }

        &-overlay {
            background-image: url('../images/frontpage/app-banner-bg.jpg'); 
        }
    }
}

.frontpage-content {
    &-wrapper {
        display: flex;
        margin: 0 auto;
        padding: baseline(36) 0 baseline(42) 0;
        position: relative;
        width: $page;
    }

    &-block {
        margin: 0 0 baseline(10) 10%;
        width: 40%;

        &-title {
            font-size: ms(8);
            margin: 0 0 baseline(3) 0;
        }
    }

    &-blocks {
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        width: 65%;
    }

    &-pricing {
        background: $white;

        .wrapper {
            padding: baseline(36) 0;
        }

        &-title,
        &-subtitle {
            margin: 0;
            text-align: center;
        }

        &-subtitle {         
            margin-top: baseline(5);
        }
    }

    &-testimonials {
        background: $white;
    }

    &-text {
        position: relative;
        width: 30%;

        &:before {
            content: attr(data-letter);
            font-family: $font-serif;
            font-size: ms(40);
            left: baseline(-16);
            line-height: 1;
            opacity: .125;
            position: absolute;
            top: baseline(-16);
        }

        &-additional-text {
            font-family: $font-decorative;
            font-size: ms(5);
        }

        &-cta {
            &-wrapper {
                margin-top: baseline(8);

                .btn {
                    margin-right: baseline(5);
                }
            }
        }

        &-desc {
            font-size: ms(-1);
            
            &-white {
                color: $white;  
            }
        }

        &-title {
            line-height: 1.1;
            margin: 0;
            position: relative;
            z-index: 1;
        }
    }

    &-why {
        background: $white;
    }

    &-music {
        background: $white;
        overflow: hidden;
        position: relative;

        &-covers {
            display: flex;
            position: absolute;
            left: 41.75%;
            top: baseline(30);
            width: 1575px;
        }

        &-cover {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            border-radius: 3px;
            height: 440px;
            margin-left: 25px;
            overflow: hidden;
            width: 290px;

            &:first-child {
                margin-left: 0;
            }

            &:nth-child(odd) {
                margin-top: 70px;
            }

            &-1 {
                background-image: url('../images/frontpage/cover-1.jpg');
            }

            &-2 {
                background-image: url('../images/frontpage/cover-2.jpg');
            }

            &-3 {
                background-image: url('../images/frontpage/cover-3.jpg');
            }

            &-4 {
                background-image: url('../images/frontpage/cover-4.jpg');
            }

            &-5 {
                background-image: url('../images/frontpage/cover-5.jpg');
            }
        }
    }
}

.calmchristian-blog-featured {
    background: $white;
    padding: baseline(25) 0;

    .wrapper {
        display: flex;
        flex-wrap: wrap;

        .featured-blog-item-intro {
            margin: baseline(10) baseline(3);
            width: calc((100% / 3) - #{baseline(6)});

            &-title {
                padding: baseline(5) 0 baseline(3) 0;
                position: relative;
                z-index: 1;

                &:before {
                    content: attr(data-letter);
                    font-size: ms(32);
                    left: baseline(-7);
                    line-height: 1;
                    opacity: .075;
                    position: absolute;
                    top: baseline(-3); 
                    z-index: 0; 
                }
            }

            &-addon {
                display: block;
                font-family: $font-decorative;
                font-size: ms(3);
                padding: 0 0 baseline(5) 0;
            }
        }
    }
}

@media (max-width: 1700px) {
    .frontpage-content {
        &-wrapper {
            overflow: hidden;
            padding: baseline(36) baseline(18) baseline(42) baseline(18);
            max-width: 100%;
        }
    }

    .frontpage-content-music-cover {
        height: 302px;
        width: 200px;
    }

    .frontpage-content-text {
        width: 35%;
    }

    .frontpage-block-app-content {
        left: 5%;
    }
}

@media (max-width: 1280px) {
    .frontpage-content-text {
        width: 50%;
    }

    .frontpage-content-wrapper {
        flex-wrap: wrap;
    }

    .frontpage-content-music-covers {
        margin-top: baseline(10);
        position: static;
        width: 100%;
    }

    .frontpage-content-music-cover {
        height: auto;
        margin-left: 2.5%;
        padding-bottom: 27.18%;
        width: 18%;
    }

    .frontpage-content-music-cover-1 {
        margin-left: 0;
    }

    .frontpage-content-music-cover-1,
    .frontpage-content-music-cover-3,
    .frontpage-content-music-cover-5 {
        margin-top: 5%!important;
    }

    .frontpage-content-music-cover-2,
    .frontpage-content-music-cover-4 {
        margin-bottom: 5%;
    }

    .frontpage-content-why {
        .frontpage-content-wrapper {
            flex-wrap: wrap;
        }
        
        .frontpage-content-blocks {
            margin-top: baseline(10);
            width: 100%;
        }

        .frontpage-content-block {
            margin: 0 10% baseline(10) 0;   
        }
    }
 
    .frontpage-block-enhance-content {
        left: auto;
        position: absolute;
        right: 5%;
    }
}

@media (max-width: 1040px) {
    .frontpage-content-text {
        width: 100%;
    }

    .frontpage-content-text:before {
        font-size: ms(30);
        left: baseline(-12);
        top: baseline(-6);
    }

    .frontpage-content {
        &-wrapper {
            padding: baseline(15) baseline(8);
        }
    }

    .testimonials-wrapper {
        padding-left: baseline(2);
        padding-right: baseline(2);
    }

    .frontpage-block-app-content, 
    .frontpage-block-enhance-content {
        left: 5%;
        right: auto;
    }
}

@media (max-width: 720px) {
    .frontpage-content-why {
        .frontpage-content-block {
            margin: 0 0 baseline(10) 0;  
            width: 100%; 
        }
    }

    .frontpage-block-app-content, 
    .frontpage-block-enhance-content {
        width: 90%;
    }
}

@media (max-width: 600px) {
    .frontpage-content {
        &-wrapper {
            overflow: hidden;
            padding: baseline(20) baseline(6) baseline(20) baseline(6);
        }
    }

    .frontpage-content-text-additional-text,
    .frontpage-block-enhance-additional-text,
    .frontpage-block-app-additional-text {
        display: block;
        margin-left: 0;
        margin-top: baseline(2);
        text-align: center;
    }
}